import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          pdfEnabled: true,
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'sourcePerformance',
          displayName: 'Source',
          iconClass: 'fa fa-list',
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'vehiclePerformance',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/leads',
          route: 'process-performance',
          displayName: 'Process Performance',
          iconClass: 'fa fa-exchange',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
      ]
    },
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: undefined,
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
        {
          parent: '/sales',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          roles: undefined,
          breadcrumbHelpEnabled: true
        },
      ]
    },
    {
      id: 'digitalRetailing',
      category: 'digitalRetailing',
      default: false,
      defaultRoute: '/provider-tools/digital-retailing-overview',
      displayName: 'Digital Retailing',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [{
            parent: '/provider-tools',
            route: 'digital-retailing-overview',
            displayName: 'Overview',
            pdfEnabled: true,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
          },
          {
            parent: '/provider-tools',
            route: 'digital-retailing',
            displayName: 'Online Engagement',
            pdfEnabled: true,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
          },
          {
            parent: '/provider-tools',
            route: 'digital-retailing-in-store',
            displayName: 'In-Store Engagement',
            pdfEnabled: true,
            breadcrumbHelpEnabled: true,
            iconClass: 'fa fa-users',
            roles: undefined
          },
      ]
    },
    {
      id: 'resources',
      category: 'resources',
      defaultRoute: '/resources/resources',
      displayName: 'Resources',
      displayInNav: true,
      iconClass: 'fa fa-wrench',
      roles: undefined,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/resources',
          route: 'resources',
          displayName: 'Resources',
          iconClass: 'fa fa-wrench',
          roles: undefined,
        }
      ]
    },
    {
      id: 'user-activity',
      category: 'user-activity',
      default: true,
      defaultRoute: '/user-activity/overview',
      displayName: 'User Activity',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: ['System Administrator', 'Corporate', "System Analyst"],
      children: [
        {
        parent: '/user-activity',
        route: 'overview',
        displayName: 'Overview',
        pdfEnabled: false,
        iconClass: 'fa fa-users',
        breadcrumbHelpEnabled: true,
        roles: ['System Administrator', 'Corporate', "System Analyst"],
      }]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', "System Analyst"],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
          roles: ['System Administrator', "System Analyst"]
        },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
          roles: ['System Administrator', "System Analyst"]
        }
      ]
    }
  ];
}
