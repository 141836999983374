import { IDefaultTableColumnConfig, BillboardConfigMap, Segment, IPropertyOverride } from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { IProcessPerformanceConfig } from './process-performance.config.interfaces';

export class ProcessPerformanceConfig implements IProcessPerformanceConfig {

  public summaryTableTitle: string = 'Dealer Summary';

  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' },
    { name: 'Trend', value: 'Trend' }
  ];

  public billboards: BillboardConfigMap[] = [
    {
      title: 'Dealer Response Rate < 30 Min',
      iconClass: 'fa-percent fa-primary',
      metricPropertyName: 'avgResponseRate30',
      metricTrendArrowPropertyName: 'avgResponseRate30MOM',
      metricMOMPropertyName: 'avgResponseRate30MOM',
      metricYOYPropertyName: 'avgResponseRate30YOY',
      metricPreviousMonthPropertyName: 'avgResponseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'avgResponseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'avgResponseRate30PreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      helpTextKey: 'ProcessPerformanceResponseRate30MinBillboardHelp',
      helpTextTitle: 'Process Performance',
      chartConfig: {
        chartType: 'bar',
        metricType: 'ResponseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
        brandLabel: 'National',
        districLabel: 'Sub-Area',
        dealerGroupLabel: 'Dealer Group',
        categoryPropertyName: '',
        seriesPropertyName: ''
      }
    },
    {
      title: 'Customer Response Rate',
      iconClass: 'fa-percent fa-primary',
      metricPropertyName: 'avgCustomersRepliedRate',
      metricTrendArrowPropertyName: 'avgCustomersRepliedRateMOM',
      metricMOMPropertyName: 'avgCustomersRepliedRateMOM',
      metricYOYPropertyName: 'avgCustomersRepliedRateYOY',
      metricPreviousMonthPropertyName: 'avgCustomersRepliedRatePreviousMonth',
      metricPreviousMTDPropertyName: 'avgCustomersRepliedRatePreviousMTD',
      metricPreviousYearPropertyName: 'avgCustomersRepliedRatePreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      helpTextKey: 'ProcessPerformanceCustomerResponseRateBillboardHelp',
      helpTextTitle: 'Process Performance',
      chartConfig: {
        chartType: 'bar',
        metricType: 'CustomersRepliedRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
        brandLabel: 'National',
        districLabel: 'Sub-Area',
        dealerGroupLabel: 'Dealer Group',
        categoryPropertyName: '',
        seriesPropertyName: ''
      }
    },
    {
      title: 'Rolling Close Rate',
      iconClass: 'fa-percent fa-primary',
      metricPropertyName: 'avgCloseRate',
      metricTrendArrowPropertyName: 'avgCloseRateMOM',
      metricMOMPropertyName: 'avgCloseRateMOM',
      metricYOYPropertyName: 'avgCloseRateYOY',
      metricPreviousMonthPropertyName: 'avgCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'avgCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'avgCloseRatePreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      helpTextKey: 'ProcessPerformanceCloseRateBillboardHelp',
      helpTextTitle: 'Process Performance',
      chartConfig: {
        chartType: 'bar',
        metricType: 'CloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
        dataSet: constants.processPerformanceDataSetKeys.billboardMetric,
        brandLabel: 'National',
        districLabel: 'Sub-Area',
        dealerGroupLabel: 'Dealer Group',
        categoryPropertyName: '',
        seriesPropertyName: ''
      }
    },
  ];

  public funnelSegments: Segment[] = [
    {
      name: 'Total Leads',
      value: 'leads',
      metricFormatKey: constants.formatKeys.localeString,
      colorPropertyName: 'leadsMOM'
    },
    {
      name: 'Dealer Response Rate < 30 min',
      value: 'responseRate30',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName: 'responseRate30MOM'
    },
    {
      name: 'Customer Response Rate',
      value: 'customersRepliedRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName: 'customersRepliedRateMOM'
    },
    {
      name: 'Appointment Scheduled Rate',
      value: 'appointmentRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName: 'appointmentRateMOM'
    },
    {
      name: 'Rolling Close Rate',
      value: 'closeRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa,
      colorPropertyName: 'closeRateMOM'
    }
  ]

  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // can use row to determine entity type if needed
    {
      show: true,
      header: 'Total Leads',
      columnDef: 'leads',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    }, {
      show: true,
      header: 'Total Leads Prev. Month',
      columnDef: 'leadsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    }, {
      show: true,
      header: 'Total Leads MOM',
      columnDef: 'leadsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate < 30 Mins',
      columnDef: 'responseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate < 30 Mins Prev. Month',
      columnDef: 'responseRate30PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate < 30 Mins MOM',
      columnDef: 'responseRate30MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate',
      columnDef: 'responseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate Prev. Month',
      columnDef: 'responseRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate MOM',
      columnDef: 'responseRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Phone',
      columnDef: 'responseRatePhone',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Phone Prev. Month',
      columnDef: 'responseRatePhonePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Phone MOM',
      columnDef: 'responseRatePhoneMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Text',
      columnDef: 'responseRateText',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Text Prev. Month',
      columnDef: 'responseRateTextPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Text MOM',
      columnDef: 'responseRateTextMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Email',
      columnDef: 'responseRateEmail',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Email Prev. Month',
      columnDef: 'responseRateEmailPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Dealer Response Rate by Email MOM',
      columnDef: 'responseRateEmailMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },

    {
      show: true,
      header: 'Customer Response Rate',
      columnDef: 'customersRepliedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate Prev. Month',
      columnDef: 'customersRepliedRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate MOM',
      columnDef: 'customersRepliedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Phone',
      columnDef: 'customerResponseRatePhone',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Phone Prev. Month',
      columnDef: 'customerResponseRatePhonePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Phone MOM',
      columnDef: 'customerResponseRatePhoneMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Text',
      columnDef: 'customerResponseRateText',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Text Prev. Month',
      columnDef: 'customerResponseRateTextPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Text MOM',
      columnDef: 'customerResponseRateTextMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Email',
      columnDef: 'customerResponseRateEmail',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Email Prev. Month',
      columnDef: 'customerResponseRateEmailPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate by Email MOM',
      columnDef: 'customerResponseRateEmailMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Appointment Scheduled Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    }, {
      show: true,
      header: 'Appointment Scheduled Rate Prev. Month',
      columnDef: 'appointmentRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    }, {
      show: true,
      header: 'Appointment Scheduled Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Appointment Showed Rate',
      columnDef: 'appointmentsShowedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
  },
  {
      show: false,
      header: 'Prev. Month Appointment Showed Rate',
      columnDef: 'appointmentsShowedRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
  },
  {
      show: false,
      header: 'Appointment Showed Rate MOM',
      columnDef: 'appointmentsShowedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
  },
    {
      show: true,
      header: 'Rolling Close Rate',
      columnDef: 'closeRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    }, {
      show: true,
      header: 'Rolling Close Rate Prev. Month',
      columnDef: 'closeRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    }, {
      show: true,
      header: 'Rolling Close Rate MOM',
      columnDef: 'closeRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    }
  ];
}
