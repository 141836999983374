import {
    IDefaultTableColumnConfig,
    IWebsiteSummaryConfig,
    IDualAxisLineGraphConfig,
    ICardHeaderConfig,
    INameValuePair} from '../../../../_shared/models/models-index';

export class WebsiteSummaryConfig implements IWebsiteSummaryConfig {
    public reportTitle: string;
    public useDefaultComparisonColumns: true;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IDualAxisLineGraphConfig = null;
    public trendTableMetrics: INameValuePair[] = [];
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}
