<div class="card card-no-border mat-elevation-z1 panel-card indexed-visualization-panel" #tableCard>
    <div class="card-header">
        <div class="pull-left">
            <!-- <h5> -->
            <!-- <i [ngClass]="currentIndex.iconClass" class="fa fa-icon pad-right"></i> -->
            <mat-form-field style="width: 200px;">
                <b><mat-select [value]="selectedConfiguration.config" (selectionChange)="indexChanged($event)">
                        <mat-option *ngFor="let config of panelConfigs" [value]="config">
                            {{config.label ?? config.name}}
                        </mat-option>
                    </mat-select></b>
            </mat-form-field>
            <!-- </h5> -->
        </div>
        <div class="pull-right">
          <ng-container *ngFor="let selectedMetric of selectedMetrics; let i = index">
            <mat-form-field *ngIf="panelMetrics?.length > 1">
              <mat-select [value]="selectedMetric" (selectionChange)="selectedMetricChanged($event, i)">
                <mat-option *ngFor="let panelMetric of panelMetrics" [value]="panelMetric.name">
                  {{panelMetric.displayName ?? panelMetric.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            &nbsp;
          </ng-container>

          <button *ngIf="!this.panelConfig.disableExpand" mat-icon-button class="toggle-expand-button nofocus" appToggleExpandoCard [scrollToElement]="tableCard" (onExpandoClick)='toggleExpanded($event)'>
            <mat-icon class="fa-primary">fullscreen</mat-icon>
          </button>
          <ng-container *ngIf="selectedConfiguration.config.helpTextKey">
            <button title="Help" mat-button class="card-options-button" [matMenuTriggerFor]="menu">
              <i class="fa fa-primary fa-ellipsis-v" style="font-size: 1.4em;"></i>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <ng-container *ngIf="!!exportName">
      <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, false)">Excel</button>
      <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, true)">Excel - {{'Export All' |
          translate: locale}}</button>
      </ng-container> -->
              <button title="Help" mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">
                {{'Help' | translate}}
              </button>
            </mat-menu>
          </ng-container>
        </div>
    </div>
    <!-- visualization/element -->
    <ng-template dynamicComponent></ng-template>
</div>
