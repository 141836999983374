import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { SharedTranslationService } from '../locale/translation/shared-translation.service';
import { LocaleService } from '../services/services-index';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
@Pipe({ name: 'translate', pure: true })
export class TranslateBasePipe implements PipeTransform {
  private currentLocale: string;

  constructor(private translationService: SharedTranslationService,
    private localeService: LocaleService) {
    this.localeService.locale$
      .pipe(takeUntilDestroyed())
      .subscribe(locale => {
        this.currentLocale = locale;
      });
  }
  
  transform(val: any, locale?: string): string {
    // Use the provided locale if available, otherwise use the current locale from the service
    const effectiveLocale = locale || this.currentLocale;

    // Early return if English or no value
    if (effectiveLocale === 'en' || !val) {
      return val;
    }

    return this.translationService.getLabelTranslation(val, effectiveLocale);
  }
}
