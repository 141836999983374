import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IUserActivityOverviewConfig,
  IDualAxisLineGraphConfig,
  IMultiLevelDualAxisLineGraphConfig,
  ICardHeaderConfig,
  IAnalysisVisualizationHeaderConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';
import * as Models from '../../../../_shared/models/models-index';


export class UserActivityOverviewConfig implements IUserActivityOverviewConfig {
  summaryTableColumns: IDefaultTableColumnConfig[];
  tableSelect: ICardHeaderConfig[];
  reportTitle: string = 'User Activity Overview';
  useDefaultComparisonColumns: boolean;

  metricOneColorHexCode: string = '#ba0a31';
  metricTwoColorHexCode: string = '#3a3a3a';

  public summaryTableConfig: IAnalysisVisualizationHeaderConfig = {
    visualizationOptions: {
      defaultPerspective: 'userSummary',
      perspectives: [
        {
          code: 'userSummary',
          type: Enums.dataTableTypes.pagedSorted,
          displayName: 'User Summary',
          iconClass: 'fa-pie-chart',
          helpConfig: {
            enabled: true,
            helpTextKey: 'UserActivityUserSummaryHelp',
            helpTextTitle: 'User Summary'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: false,
            //selectedMetricId: Enums.hospitalityWebsiteMetrics.bookings.metricId,
            metricSelectConfig: null
          },
          dataSliceConfig: {
            enabled: false,
            sliceOptions: null
          },
          metricDisplayModes: {
            enabled: false,
            metricDisplayModes: null
          }
        },
        {
          code: 'dealerSummary',
          type: Enums.dataTableTypes.hierarchy,
          displayName: 'Dealer Summary',
          iconClass: 'fa-calendar-check-o fa-solid',
          helpConfig: {
            enabled: true,
            helpTextKey: 'UserActivityDealerSummaryHelp',
            helpTextTitle: 'Dealer Summary'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: false,
            //selectedMetricId: null,
            metricSelectConfig: null
          },
          dataSliceConfig: {
            enabled: false,
            sliceOptions: null
          },
          metricDisplayModes: {
            enabled: true,
            metricDisplayModes: [
                { name: 'MOM', value: 'MOM' },
                { name: 'YOY', value: 'YOY' }
            ]
          }
        },
        {
          code: 'userTransactions',
          type: Enums.dataTableTypes.pagedSorted,
          displayName: 'User Activity',
          iconClass: 'fa-calendar-check-o fa-solid',
          helpConfig: {
            enabled: true,
            helpTextKey: 'UserActivityUserTransactionHelp',
            helpTextTitle: 'User Activity'
          },
          expando: {
            enabled: true
          },
          metricSelect: {
            enabled: false,
            //selectedMetricId: null,
            metricSelectConfig: null
          },
          dataSliceConfig: {
            enabled: false,
            sliceOptions: null
          },
          metricDisplayModes: {
            enabled: false,
            metricDisplayModes: null
          }
        }
      ]
    }
  }

  public billboards: Models.BillboardConfigMap[] = [
    {
      title: 'Sessions',
      iconClass: 'fa-users fa-primary',
      metricPropertyName: 'sessionStarts',
      metricMOMPropertyName: 'sessionStartsMOM',
      metricYOYPropertyName: 'sessionStartsYOY',
      metricPreviousMonthPropertyName: 'sessionStartsPreviousMonth',
      metricPreviousMTDPropertyName: 'sessionStartsPreviousMTD',
      metricPreviousYearPropertyName: 'sessionStartsPreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: 'UserActivitySessionsBillboardHelp',
      helpTextTitle: 'Session Starts',
      // chartConfig:'line-chart'
    },
    {
      title: 'Page Views',
      iconClass: 'fa-users fa-primary',
      metricPropertyName: 'pageViews',
      metricMOMPropertyName: 'pageViewsMOM',
      metricYOYPropertyName: 'pageViewsYOY',
      metricPreviousMonthPropertyName: 'pageViewsPreviousMonth',
      metricPreviousMTDPropertyName: 'pageViewsPreviousMTD',
      metricPreviousYearPropertyName: 'pageViewsPreviousYear',
      useFooterRowOne: true,
      useFooterRowTwo: true,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      metricFormatKey: constants.formatKeys.localeString,
      helpTextKey: 'UserActivityPageViewsBillboardHelp',
      helpTextTitle: 'Page Views',
      // chartConfig:'line-chart'
    },
  ];
}
