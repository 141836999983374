import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';

export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    dateModeOptions = [
        Enums.dateModes.currentMonth,
        Enums.dateModes.lastMonth,
        Enums.dateModes.lastThirtyDays,
        Enums.dateModes.previousThreeMonths,
        Enums.dateModes.yearToDate,
        Enums.dateModes.previousTwelveMonths
    ]
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
        'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
        'source-type': { displayName: 'Source Type', name: 'source-type' },
        'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
        'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
        'digad-program': { displayName: 'Program', name: 'digad-program' },
        'role': { displayName: 'Role', name: 'role' },
        'page': { displayName: 'Page', name: 'page' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Leads
        'LeadsOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: 'LeadsOverview',
            filters: ['org', 'lead-type', 'source-type'],
            defaults: ['org', 'lead-type', 'source-type'],
            section: 'leads',
            orgTypes: [1, 3]
        },
        'LeadSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'LeadSummary',
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1, 3]
        },
        'LeadSourcePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'LeadSourcePerformance',
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1, 3]
        },
        'LeadVehiclePerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'LeadVehiclePerformance',
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1, 3]
        },
        'LeadTransactions': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'LeadTransactions',
            filters: [
                'org', 'lead-type', 'source-type', 'vehicle-model'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1, 3]
        },
        'ProcessPerformance': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'ProcessPerformance',
            filters: [
                'org', 'lead-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'source-type'
            ],
            section: 'leads',
            orgTypes: [1, 3]
        },
        // Sales
        'SalesOverview': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'SalesOverview',
            filters: ['org', 'lead-type'],
            defaults: ['org', 'lead-type'],
            section: 'sales',
            orgTypes: [1, 3]
        },
        'SalesSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'SalesSummary',
            filters: [
                'org', 'sale-type', 'lead-type', 'source-type',
            ],
            defaults: [
                'org', 'sale-type', 'lead-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1, 3]
        },
        'SalesVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: 'SalesVehicleSummary',
            filters: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            defaults: [
                'org', 'lead-type', 'sale-type', 'source-type'
            ],
            section: 'sales',
            orgTypes: [1, 3],
            defaultMonthDateMode: 'sales'
        },
        // Digital Retailing
        'DigitalRetailingOverview': {
            reportName: 'DigitalRetailingOnlineEngagement',
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'device-type', 'digital-retailing-provider'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 3]
        },
        'DigitalRetailingDashboard': {
            reportName: 'DigitalRetailingDashboard',
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 3]
        },
        'DigitalRetailingInStore': {
            reportName: 'DigitalRetailingInStore',
            dateMode: Enums.dateModes.currentMonth,
            filters: ['org', 'digital-retailing-provider'],
            defaults: ['org'],
            section: 'provider-tools',
            orgTypes: [1, 3]
        },
        // User Activity
        'UserActivityOverview': {
            reportName: 'UserActivityOverview',
            dateMode: Enums.dateModes.currentMonth,
            filters: ['role', 'page'],
            defaults: ['role', 'page'],
            section: 'user-activity',
            orgTypes: [1, 3]
        }
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [12, 15, 18, 19, 20],
            selected: [
                { value: 15, display: 'CarNow' },
                { value: 18, display: 'ActivEngage' },
                { value: 12, display: 'Dealer Inspire' },
                { value: 19, display: 'Gubagoo' },
                { value: 20, display: 'Podium' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Vehicle' }],
            removable: true,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 1, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: '3rd Party' },
                { value: 3, display: 'Dealer Website' },
                { value: 5, display: 'Event' },
                { value: 7, display: 'Digital Retailing: Online' },
                { value: 8, display: 'Tier II' },
                { value: 10, display: 'Chat' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [3, 5, 12, 14],
            selected: [
                { value: 3, display: 'DealerOn' },
                { value: 5, display: 'Sincro' },
                { value: 12, display: 'Dealer Inspire' },
                { value: 14, display: 'Fox Dealer' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [2, 4, 5, 8, 13, 14, 15, 16],
            selected: [
                { value: 2, display: 'Paid Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 8, display: 'Paid Social' },
                { value: 13, display: 'ATV' },
                { value: 14, display: 'OTT' },
                { value: 15, display: 'Streaming Audio' },
                { value: 16, display: 'Mobile Gaming' },
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
            type: 'digad-provider',
            available: [3, 5, 11, 14, 21, 22, 23],
            selected: [
                { value: 3, display: 'DealerON' },
                { value: 5, display: 'Sincro' },
                { value: 11, display: 'SoKal' },
                { value: 14, display: 'Fox Dealer' },
                { value: 21, display: 'Constellation' },
                { value: 22, display: 'Stream' },
                { value: 23, display: 'Force Marketing' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'digital-retailing-provider': {
            type: 'digital-retailing-provider',
            available: [15, 16, 17, 19, 24, 25],
            selected: [
                { value: 15, display: 'CarNow' },
                { value: 16, display: 'Roadster' },
                { value: 17, display: 'TagRail' },
                { value: 19, display: 'Gubagoo' },
                { value: 24, display: 'Modal' },
                { value: 25, display: 'Darwin Automotive' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'role': {
            type: 'role',
            available: [2, 6],
            selected: [
                { value: 2, display: 'Corporate' },
                { value: 6, display: 'Dealer' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'page': {
            type: 'page',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            selected: [
                { value: 1, display: 'Leads Overview' },
                { value: 2, display: 'Leads Summary' },
                { value: 3, display: 'Leads Source Performance' },
                { value: 4, display: 'Leads Vehicle Performance' },
                { value: 5, display: 'Leads Transaction' },
                { value: 6, display: 'Leads Process Performance' },
                { value: 7, display: 'Sales Overview' },
                { value: 8, display: 'Sales Summary' },
                { value: 9, display: 'Sales Vehicle' },
                { value: 10, display: 'Digital Retailing Dashboard' },
                { value: 11, display: 'Online Engagement' },
                { value: 12, display: 'In-Store Engagement' },
            ],
            removable: false,
            lockable: false,
            locked: false
        },
    };
}
