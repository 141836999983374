import {
  ILeadsOverviewBillboardConfig,
  ILeadsOverviewConfig,
  ILeadsOverviewDealerKpiConfig,
  IDefaultTableColumnConfig,
  ILeadsOverviewKpiConfig,
  ILeadsOverviewSourceTypeCardConfig,
  IPropertyOverride
} from '../../../../_shared/models/models-index';

import * as constants from '../../../../_shared/constants/constants';
export class LeadsOverviewConfig implements ILeadsOverviewConfig {

  public reportTitle = 'Leads Overview';
  metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];

  useDefaultComparisonColumns = true;

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'bestMatchSales',
      overrideProperty: 'sameDealerBestMatchSales'
    },
    {
      baseProperty: 'bestMatchCloseRate',
      overrideProperty: 'sameDealerBestMatchCloseRate'
    },
    {
      baseProperty: 'bestMatchCloseRate30',
      overrideProperty: 'sameDealerBestMatchCloseRate30'
    },
    {
      baseProperty: 'bestMatchCloseRate60',
      overrideProperty: 'sameDealerBestMatchCloseRate60'
    },
    {
      baseProperty: 'bestMatchCloseRate90',
      overrideProperty: 'sameDealerBestMatchCloseRate90'
    },
  ];

  public sourceTypeCardConfig: ILeadsOverviewSourceTypeCardConfig = {
    showComparisonMetrics: true,
    leadMetric: {
      name: 'Unique Leads',
      value: 'uniqueLeads'
    },
    closeRateMetric: {
      name: 'Rolling Close Rate',
      value: 'bestMatchCloseRate'
    }
  }

  public billboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Response Rate < 30 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate30',
      metricMOMPropertyName: 'responseRate30MOM',
      metricYOYPropertyName: 'responseRate30YOY',
      metricPreviousMonthPropertyName: 'responseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate30PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate30MOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'LeadsOverviewResponseRateBillboardHelp',
      helpTextTitle: '% Response Rate < 30 Min'
    },
    {
      title: 'Avg. Response Time',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: true,
      metricCurrentPropertyName: 'averageResponseTime',
      metricMOMPropertyName: 'averageResponseTimeMOM',
      metricYOYPropertyName: 'averageResponseTimeYOY',
      metricPreviousMonthPropertyName: 'averageResponseTimePreviousMonth',
      metricPreviousMTDPropertyName: 'averageResponseTimePreviousMTD',
      metricPreviousYearPropertyName: 'averageResponseTimePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'averageResponseTimeMOM',
      metricFormatKey: constants.formatKeys.minutesTimeString,
      helpTextKey: 'LeadsOverviewAvgResponseTimeBillboardHelp',
      helpTextTitle: 'Avg. Response Time'
    },
    {
      title: 'Rolling Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'bestMatchCloseRate',
      metricMOMPropertyName: 'bestMatchCloseRateMOM',
      metricYOYPropertyName: 'bestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'bestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'bestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'bestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'bestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Rolling Close Rate'
    },
  ];

  public dealerbillboards: ILeadsOverviewBillboardConfig[] = [
    {
      title: 'Lead Volume',
      subtitle: '',
      iconClass: 'fa-users fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'leadVolume',
      metricMOMPropertyName: 'leadVolumeMOM',
      metricYOYPropertyName: 'leadVolumeYOY',
      metricPreviousMonthPropertyName: 'leadVolumePreviousMonth',
      metricPreviousMTDPropertyName: 'leadVolumePreviousMTD',
      metricPreviousYearPropertyName: 'leadVolumePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'leadVolumeMOM',
      metricFormatKey: constants.formatKeys.abbreviatedLocaleString,
      helpTextKey: 'LeadsOverviewLeadVolumeBillboardHelp',
      helpTextTitle: 'Lead Volume'
    },
    {
      title: 'Response Rate < 30 Min',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'responseRate30',
      metricMOMPropertyName: 'responseRate30MOM',
      metricYOYPropertyName: 'responseRate30YOY',
      metricPreviousMonthPropertyName: 'responseRate30PreviousMonth',
      metricPreviousMTDPropertyName: 'responseRate30PreviousMTD',
      metricPreviousYearPropertyName: 'responseRate30PreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'responseRate30MOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'LeadsOverviewResponseRateBillboardHelp',
      helpTextTitle: '% Response Rate < 30 Min'
    },
    {
      title: 'Avg. Response Time',
      subtitle: '',
      iconClass: 'fa-calendar fa-primary',
      reverseMetric: true,
      metricCurrentPropertyName: 'averageResponseTime',
      metricMOMPropertyName: 'averageResponseTimeMOM',
      metricYOYPropertyName: 'averageResponseTimeYOY',
      metricPreviousMonthPropertyName: 'averageResponseTimePreviousMonth',
      metricPreviousMTDPropertyName: 'averageResponseTimePreviousMTD',
      metricPreviousYearPropertyName: 'averageResponseTimePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'averageResponseTimeMOM',
      metricFormatKey: constants.formatKeys.minutesTimeString,
      helpTextKey: 'LeadsOverviewAvgResponseTimeBillboardHelp',
      helpTextTitle: 'Avg. Response Time'
    },
    {
      title: 'Rolling Close Rate',
      subtitle: '',
      iconClass: 'fa-percent fa-primary',
      reverseMetric: false,
      metricCurrentPropertyName: 'sameDealerBestMatchCloseRate',
      metricMOMPropertyName: 'sameDealerBestMatchCloseRateMOM',
      metricYOYPropertyName: 'sameDealerBestMatchCloseRateYOY',
      metricPreviousMonthPropertyName: 'sameDealerBestMatchCloseRatePreviousMonth',
      metricPreviousMTDPropertyName: 'sameDealerBestMatchCloseRatePreviousMTD',
      metricPreviousYearPropertyName: 'sameDealerBestMatchCloseRatePreviousYear',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
      footerRowCount: 1,
      footerTwoLeftLabel: 'MOM',
      footerTwoRightLabel: 'YOY',
      footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
      showTrendArrow: true,
      trendArrowDrivingPropertyName: 'sameDealerBestMatchCloseRateMOM',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      helpTextKey: 'LeadsOverviewCloseRateBillboardHelp',
      helpTextTitle: 'Rolling Close Rate'
    },
  ];

  public dealerKpiCardConfig: ILeadsOverviewDealerKpiConfig = {
    metrics: {
      'CloseRate': {
        yAxisTitle: 'Rolling Close Rate',
        displayName: 'Rolling Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'sameDealerBestMatchCloseRate',
        metricFormatKey: null,
        formatter: null
      },
      'LeadVolume': {
        yAxisTitle: 'Lead Volume',
        displayName: 'Lead Volume',
        pointLabelFormat: null,
        propertyName: 'leadVolume',
        metricFormatKey: null,
        formatter: null
      },
      'ResponseRate': {
        yAxisTitle: '% Responded < 30 Min',
        displayName: 'Responded < 30 Min',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate30',
        metricFormatKey: null,
        formatter: null
      }
    }
  };

  public kpiCardConfig: ILeadsOverviewKpiConfig = {
    metrics: {
      'ResponseRate': {
        yAxisTitle: '% Responded < 30 Min',
        displayName: 'Response Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'responseRate30',
        metricFormatKey: constants.formatKeys.percentage,
        formatter: (val) => Math.round(val * 100)
      },
      'CloseRate': {
        yAxisTitle: 'Rolling Close Rate',
        displayName: 'Rolling Close Rate',
        pointLabelFormat: '{value}%',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals,
        formatter: (val) => Math.round(val * 10000) / 100
      }
    }
  };

  public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    // Lead Volume
    {
      show: true, header: 'Total Leads',
      columnDef: 'leadVolume',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Unique Lead Volume
    {
     show: true, header: 'Total Unique Leads',
     columnDef: 'uniqueLeads',
     hasComparisonMetrics: true,
     metricFormatKey: constants.formatKeys.localeString,
     comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
     clickable: false
    },
    // Response Rate < 1 hr
    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Average Response Time
    {
      show: true,
      header: 'Avg. Response Time',
      columnDef: 'averageResponseTime',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.minutesTimeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Close Leads
    {
      show: true,
      header: 'Closed Leads',
      columnDef: 'bestMatchSales',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.localeString,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // Rolling Close Rate
    {
      show: true,
      header: 'Rolling Close Rate',
      columnDef: 'bestMatchCloseRate',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 30 Day Rolling Close Rate
    {
      show: true,
      header: '30-Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 60 Day Rolling Close Rate
    {
      show: true,
      header: '60-Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
    // 90 Day Rolling Close Rate
    {
      show: true,
      header: '90-Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      hasComparisonMetrics: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      comparisonFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false
    },
  ];
}
