import {
    IWebsiteScorecardConfig,
    IWebsiteScorecardCategoryConfig } from '../../../../_shared/models/models-index';
    import * as constants from '../../../../_shared/constants/constants';

export class WebsiteScorecardConfig implements IWebsiteScorecardConfig {
    public reportTitle: string;
    public scorecards: IWebsiteScorecardCategoryConfig[] = [];

}
