import { IDefaultTableColumnConfig,
  IDigitalRetailingInStoreConfig,
  IMultiLevelDualAxisLineGraphConfig,
  IDualAxisLineGraphConfig,
  INameValuePair } from '../../../../../_shared/models/models-index';
import * as constants from '../../../../../_shared/constants/constants';
import { Enums } from '../../../../../_shared/enums/enums';

export class DigitalRetailingInStoreConfig implements IDigitalRetailingInStoreConfig {


    metricDisplayModes = [
        { name: 'MOM', value: 'MOM' },
        { name: 'YOY', value: 'YOY' }
    ];

  public useDefaultComparisonColumns = true;
  public reportTitle = 'Digital Retailing In-Store Engagement';

  public pageCopy =  'All data is currently supplied monthly by each digital retailing provider participating in the Audi DR Pilot Program. Monthly data will be loaded for the month prior by the 15th of every month.';

  public trendGraphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: 1,
        displayName: Enums.digitalRetailingMetrics.sessionStarts.name,
        propertyName: 'sessionStarts',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 2,
        displayName: 'Continue Deals',
        propertyName: 'continueDeals',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 3,
        displayName: 'New Deal Starts',
        propertyName: 'newDealStarted',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 5,
        displayName: 'Trade Starts',
        propertyName: 'tradeStarts',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 6,
        displayName: 'Trade Completes',
        propertyName: 'tradeCompletes',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 7,
        displayName: 'Incentive Views',
        propertyName: 'incentiveViews',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 8,
        displayName: 'Incentive Selects',
        propertyName: 'incentiveSelects',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 9,
        displayName: 'Payment Adjustments (Lease, Finance, Cash)',
        propertyName: 'pmtAdjLeaseFinanceCash',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 10,
        displayName: 'Credit App Starts',
        propertyName: 'creditAppStarts',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 11,
        displayName: 'Credit App Completes',
        propertyName: 'creditAppFinishes',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 12,
        displayName: 'F&I Product Views',
        propertyName: 'financeProductViews',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 13,
        displayName: 'F&I Product Selects',
        propertyName: 'financeProductSelects',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 14,
        displayName: 'Accessories Selects',
        propertyName: 'accessoriesSelected',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 15,
        displayName: 'Remote Delivery Requests',
        propertyName: 'remoteDeliverRequests',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 17,
        displayName: 'Test Drive Requests',
        propertyName: 'testDriveRequests',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 18,
        displayName: 'Appointment Requests',
        propertyName: 'appointmentRequests',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 19,
        displayName: 'In-Tool Chat Sessions',
        propertyName: 'chatSessions',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 20,
        displayName: 'In-Tool Deal Adjustments',
        propertyName: 'dealerITDA',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 21,
        displayName: 'E-Dealer Forms Utilized',
        propertyName: 'eDealerFormUtilization',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 22,
        displayName: 'Full Completion of DR Experience',
        propertyName: 'dRFinishes',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: 16,
        displayName: 'Deal Pushed to DMS',
        propertyName: 'dealPushedToDMS',
        metricFormatKey: constants.formatKeys.roundToInteger
      },
    ],
        defaultMetricOneId:Enums.digitalRetailingMetrics.sessionStarts.metricId,
        defaultMetricTwoId: Enums.digitalRetailingMetrics.leadCaptures.metricId,
        metricOneColorHexCode: '#ba0a31',
        metricTwoColorHexCode: '#3a3a3a'
  };
  public trendTableMetrics: INameValuePair[] = [
    {name: 'Session Starts', value: 'sessionStarts'},
    {name: 'Continue Deals', value: 'continueDeals'},
    {name: 'New Deal Starts', value: 'newDealStarted'},
    {name: 'Trade Starts', value: 'tradeStarts'},
    {name: 'Trade Completes', value: 'tradeCompletes'},
    {name: 'Incentive Views', value: 'incentiveViews'},
    {name: 'Incentive Selects', value: 'incentiveSelects'},
    {name: 'Payment Adjustments (Lease, Finance, Cash)', value: 'pmtAdjLeaseFinanceCash'},
    {name: 'Credit App Starts', value: 'creditAppStarts'},
    {name: 'Credit App Completes', value: 'creditAppFinishes'},
    {name: 'F&I Product Views', value: 'financeProductViews'},
    {name: 'F&I Product Selects', value: 'financeProductSelects'},
    {name: 'Accessories Selects', value: 'accessoriesSelected'},
    {name: 'Remote Delivery Requests', value: 'remoteDeliverRequests'},
    {name: 'Test Drive Requests', value: 'testDriveRequests'},
    {name: 'Appointment Requests', value: 'appointmentRequests'},
    {name: 'In-Tool Chat Sessions', value: 'chatSessions'},
    {name: 'In-Tool Deal Adjustments', value: 'dealerITDA'},
    {name: 'E-Dealer Forms Utilized', value: 'eDealerFormUtilization'},
    {name: 'Full Completion of DR Experience', value: 'dRFinishes'},
    {name: 'Deal Pushed to DMS', value: 'dealPushedToDMS'},
  ];
  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    },
    {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    {
      show: true,
      header: 'Session Starts',
      columnDef: 'sessionStarts',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Continue Deals',
      columnDef: 'continueDeals',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'New Deal Starts',
      columnDef: 'newDealStarted',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Trade Starts',
      columnDef: 'tradeStarts',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Trade Completes',
      columnDef: 'tradeCompletes',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Incentive Views',
      columnDef: 'incentiveViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Incentive Selects',
      columnDef: 'incentivesSelected',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },

    {
      show: true,
      header: 'Payment Adjustments (Lease, Finance, Cash)',
      columnDef: 'pmtAdjLeaseFinanceCash',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: true,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Credit App Starts',
      columnDef: 'creditAppStarts',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Credit App Completes',
      columnDef: 'creditAppFinishes',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'F&I Product Views',
      columnDef: 'financeProductViews',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'F&I Product Selects',
      columnDef: 'financeProductSelects',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Accessories Selects',
      columnDef: 'accessoriesSelected',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Remote Delivery Requests',
      columnDef: 'remoteDeliverRequests',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Test Drive Requests',
      columnDef: 'testDriveRequests',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Appointment Requests',
      columnDef: 'appointmentRequests',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'In-Tool Chat Sessions',
      columnDef: 'chatSessions',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'In-Tool Deal Adjustments',
      columnDef: 'dealerITDA',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'E-Dealer Forms Utilized',
      columnDef: 'eDealerFormUtilization',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Full Completion of DR Experience',
      columnDef: 'dRFinishes',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Deal Pushed to DMS',
      columnDef: 'dealPushedToDMS',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
  ];

}
