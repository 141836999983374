
  <mat-card class="mat-elevation-z1" style="background-color: #ffffff;">
    <mat-card-title>
      <i [ngClass]="iconClass" class="fa pad-right"></i><b style="color: #222222">{{title}}</b>
      <div class="pull-right ellipses-dropdown">
        <button mat-button class="card-options-button" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"><i class="fa fa-ellipsis-v fa-primary" style="font-size: 1.4em;"></i></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item style="outline: none;" (click)="openHelpTextClicked()">{{'Help' | translate: locale}}</button>
        </mat-menu>
      </div>
    </mat-card-title>
    <mat-card-content style="color: #222222" class="card-block target-card">
      <table>
        <tbody>
          <tr>
            <td>
              <b>{{metricCurrent | formatter : metricFormatter}}</b>
              <i [ngClass]="[trendArrow, getTrendIconColor(metricMOM)]" class="pad-left" style="padding-left: 4px;"></i>
            </td>
          </tr>
          <tr>
            <td *ngIf="!!subtitleMetric" class="box-label">{{subtitle}}: {{subtitleMetric | formatter: subtitleMetricFormatter}}</td>
            <td *ngIf="!subtitleMetric" class="box-label">{{subtitle}}</td>
          </tr>
        </tbody>
      </table>
    </mat-card-content>
    <mat-card-footer>
      <table *ngIf="!secondaryMetric">
        <tbody>
          <tr>
            <td class="prev-metric" style="text-align: left;">
              {{footerLeftLabel | translate: locale}}:
              <b [ngClass]="getTrendColor(metricMOM)">
                {{metricMOM | formatter: footerMetricFormatter}}
              </b>
            </td>
            <td class="prev-metric" style="text-align: right;">
              {{footerRightLabel | translate: locale}}:
              <b [ngClass]="getTrendColor(metricYOY)">
                {{metricYOY | formatter: footerMetricFormatter}}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
      <table *ngIf="secondaryMetric">
        <tbody>
          <tr>
            <td rowspan="2" style="text-align: left;">
              {{secondaryMetricLabel}}: {{secondaryMetric | formatter: secondaryMetricFormatter}}
            </td>
            <td rowspan="1" style="text-align: right;">
                {{footerLeftLabel}}:
                <b [ngClass]="getTrendColor(metricMOM)">
                  {{metricMOM | formatter: footerMetricFormatter}}
                </b>
            </td>
          </tr>
          <tr>
            <td rowspan="1" style="text-align: right;">
              {{footerRightLabel}}:
              <b [ngClass]="getTrendColor(metricYOY)">
                {{metricYOY | formatter: footerMetricFormatter}}
              </b>
            </td>
          </tr>
        </tbody>
      </table>
    </mat-card-footer>
  </mat-card>
