<div class="card-header" *ngIf="!!currentPerspective" style="border-bottom: 0px">
  <div class="pull-left" style="width: 283x;">
    <h5><i [ngClass]="currentPerspective.iconClass" class="fa fa-icon pad-right"></i>
      <mat-form-field style="width: 253px;">
        <b><mat-select  [value]="currentPerspective.code" (selectionChange)="applyPerspectiveChange($event)">
           <mat-option *ngFor="let perspective of perspectives" [value]="perspective.code">
              {{perspective.displayName}}
            </mat-option>
        </mat-select></b>
     </mat-form-field>
   </h5>
  </div>
  <div class="pull-right">
    <button *ngIf="!!currentPerspective.expando.enabled" mat-icon-button class="toggle-expand-button nofocus"
      (click)='toggleExpanded($event)'>
      <mat-icon>fullscreen</mat-icon>
    </button>
    <metric-simple-select *ngIf="!!currentPerspective.metricSelect.enabled"
      [config]="currentPerspective.metricSelect.metricSelectConfig" (selectionChange)="applyMetricChange($event)">
    </metric-simple-select>
    <ng-container *ngIf="currentPerspective.helpConfig.enabled">
      <button mat-button class="card-options-button" [matMenuTriggerFor]="menu"><i class="fa fa-primary fa-ellipsis-v"
          style="font-size: 1.4em;"></i></button>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!!exportName">
          <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, false)">Excel</button>
          <button mat-menu-item style="outline: none;" (click)="printExcel(exportName, true)">Excel - {{'Export All' |
            translate: locale}}</button>
        </ng-container>
        <button mat-menu-item style="outline: none;" (click)="openHelpText(currentPerspective.helpConfig.helpTextKey, currentPerspective.helpConfig.helpTextTitle)">{{'Help' | translate:
          locale}}</button>
      </mat-menu>
    </ng-container>
  </div>
</div>
