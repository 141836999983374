import {
    IDefaultTableColumnConfig,
    IWebsiteProviderConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig } from '../../../../_shared/models/models-index';

export class WebsiteProviderConfig implements IWebsiteProviderConfig {
    public reportTitle: string;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
}