import { IDefaultTableColumnConfig, ILeadsSummaryConfig, IDualAxisLineGraphConfig, INameValuePair, IPropertyOverride } from '../../../../_shared/models/models-index';
import { Enums } from '../../../../_shared/enums/enums';
import * as constants from '../../../../_shared/constants/constants';

export class LeadsSummaryConfig implements ILeadsSummaryConfig {

  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' },
    { name: 'Trend', value: 'Trend' }
  ];

  public dealerLevelMetricOverrides: IPropertyOverride[] = [
    {
      baseProperty: 'bestMatchSales',
      overrideProperty: 'sameDealerBestMatchSales'
    },
    {
      baseProperty: 'bestMatchCloseRate',
      overrideProperty: 'sameDealerBestMatchCloseRate'
    },
    {
      baseProperty: 'bestMatchCloseRate30',
      overrideProperty: 'sameDealerBestMatchCloseRate30'
    },
    {
      baseProperty: 'bestMatchCloseRate60',
      overrideProperty: 'sameDealerBestMatchCloseRate60'
    },
    {
      baseProperty: 'bestMatchCloseRate90',
      overrideProperty: 'sameDealerBestMatchCloseRate90'
    },
    {
      baseProperty: 'bestMatchCloseRate120',
      overrideProperty: 'sameDealerBestMatchCloseRate120'
    },
  ];

  public graphConfig: IDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    availableMetrics: [
      {
        id: Enums.leadMetrics.leadVolume.metricId,
        displayName: 'Total Leads',
        propertyName: Enums.leadMetrics.leadVolume.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.uniqueLeads.metricId,
        displayName: 'Total Unique Leads',
        propertyName: Enums.leadMetrics.uniqueLeads.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      {
        id: Enums.leadMetrics.responseRate.metricId,
        displayName: 'Response Rate',
        propertyName: Enums.leadMetrics.responseRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.responseRate30.metricId,
        displayName: 'Response Rate < 30 Min',
        propertyName: Enums.leadMetrics.responseRate30.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.averageResponseTime.metricId,
        displayName: Enums.leadMetrics.averageResponseTime.name,
        propertyName: Enums.leadMetrics.averageResponseTime.nameKey,
        metricFormatKey: constants.formatKeys.minutesTimeString
      },
      {
        id: 71,
        displayName: 'Customer Response Rate',
        propertyName: 'customerResponseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.appointmentRate.metricId,
        displayName: 'Appointment Scheduled Rate',
        propertyName: Enums.leadMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: 72,
        displayName: 'Appoinment Showed Rate',
        propertyName: 'appointmentShowedRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.leadMetrics.bestMatchSales.metricId,
        displayName: Enums.leadMetrics.bestMatchSales.name,
        propertyName: Enums.leadMetrics.bestMatchSales.nameKey,
        metricFormatKey: constants.formatKeys.localeString
      },
      // Close Rate
      {
        id: Enums.leadMetrics.closeRate.metricId,
        displayName: 'Rolling Close Rate',
        propertyName: 'bestMatchCloseRate',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 90 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate90.metricId,
        displayName: '90-Day Close Rate',
        propertyName: 'bestMatchCloseRate90',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 60 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate60.metricId,
        displayName: '60-Day Close Rate',
        propertyName: 'bestMatchCloseRate60',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      // 30 Day Close Rate
      {
        id: Enums.leadMetrics.closeRate30.metricId,
        displayName: '30-Day Close Rate',
        propertyName: 'bestMatchCloseRate30',
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
    ],
    defaultMetricOneId: Enums.leadMetrics.leadVolume.metricId,
    defaultMetricTwoId: Enums.leadMetrics.uniqueLeads.metricId,
    metricOneColorHexCode: '#ba0a31',
    metricTwoColorHexCode: '#3a3a3a'
  };

  public summaryTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
      // canDrillthrough: (row) => this.helperService.canDrillthrough(this.filterModel, row.entityType)
    },
    {
      show: false,
      print: true,
      header: 'Dealer Name',
      columnDef: 'displayName',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.entityDisplayName, clickable: false
    },
    // can use row to determine entity type if needed

    {
      show: true,
      header: 'Total Leads',
      columnDef: 'leadVolume',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Total Leads',
      columnDef: 'leadVolumePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Leads MOM',
      columnDef: 'leadVolumeMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Total Leads',
      columnDef: 'leadVolumePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Leads YOY',
      columnDef: 'leadVolumeYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },


    {
      show: true,
      header: 'Total Unique Leads',
      columnDef: 'uniqueLeads',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Total Leads',
      columnDef: 'uniqueLeadsPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Leads MOM',
      columnDef: 'uniqueLeadsMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Total Leads',
      columnDef: 'uniqueLeadsPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Total Leads YOY',
      columnDef: 'uniqueLeadsYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    {
      show: true,
      header: 'Response Rate',
      columnDef: 'responseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Response Rate',
      columnDef: 'responseRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Response Rate MOM',
      columnDef: 'responseRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Response Rate',
      columnDef: 'responseRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Response Rate YOY',
      columnDef: 'responseRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    {
      show: true,
      header: 'Response Rate < 30 Min',
      columnDef: 'responseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Response Rate < 30 Min',
      columnDef: 'responseRate30PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Response Rate < 30 Min MOM',
      columnDef: 'responseRate30MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Response Rate < 30 Min',
      columnDef: 'responseRate30PreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Response Rate < 30 Min YOY',
      columnDef: 'responseRate30YOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    {
      show: true,
      header: 'Overall Avg. Response Time',
      columnDef: 'averageResponseTime',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Overall Avg. Response Time',
      columnDef: 'averageResponseTimePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
    },
    {
      show: false,
      header: 'Overall Avg. Response Time MOM',
      columnDef: 'averageResponseTimeMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Overall Avg. Response Time',
      columnDef: 'averageResponseTimePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.minutesTimeString, clickable: false
    },
    {
      show: false,
      header: 'Overall Avg. Response Time YOY',
      columnDef: 'averageResponseTimeYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    {
      show: true,
      header: 'Customer Response Rate',
      columnDef: 'customerResponseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate Prev. Month',
      columnDef: 'customerResponseRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate MOM',
      columnDef: 'customerResponseRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate Prev. Year',
      columnDef: 'customerResponseRatePreviousYear',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },
    {
      show: true,
      header: 'Customer Response Rate YOY',
      columnDef: 'customerResponseRateYOY',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimalsOrNa, clickable: false
    },

    {
      show: true,
      header: 'Appointment Scheduled Rate',
      columnDef: 'appointmentRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Appointment Scheduled Rate',
      columnDef: 'appointmentRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Appointment Scheduled Rate MOM',
      columnDef: 'appointmentRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Appointment Scheduled Rate',
      columnDef: 'appointmentRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Appointment Scheduled Rate YOY',
      columnDef: 'appointmentRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    {
      show: true,
      header: 'Appointment Showed Rate',
      columnDef: 'appointmentsShowedRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Appointment Showed Rate',
      columnDef: 'appointmentsShowedRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Appointment Showed Rate MOM',
      columnDef: 'appointmentsShowedRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Appointment Showed Rate',
      columnDef: 'appointmentsShowedRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Appointment Showed Rate YOY',
      columnDef: 'appointmentsShowedRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Closed Leads
    {
      show: true,
      header: 'Closed Leads',
      columnDef: 'bestMatchSales',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Closed Leads',
      columnDef: 'bestMatchSalesPreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Closed Leads MOM',
      columnDef: 'bestMatchSalesMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Closed Leads',
      columnDef: 'bestMatchSalesPreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.localeString, clickable: false
    },
    {
      show: false,
      header: 'Closed Leads YOY',
      columnDef: 'bestMatchSalesYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },

    // Close Rate
    {
      show: true,
      header: 'Rolling Close Rate',
      columnDef: 'bestMatchCloseRate',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month Rolling Close Rate',
      columnDef: 'bestMatchCloseRatePreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Rolling Close Rate MOM',
      columnDef: 'bestMatchCloseRateMOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year Rolling Close Rate',
      columnDef: 'bestMatchCloseRatePreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Rolling Close Rate YOY',
      columnDef: 'bestMatchCloseRateYOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // 30 Day Close Rate
    {
      show: true,
      header: '30 Day Close Rate',
      columnDef: 'bestMatchCloseRate30',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month 30 Day Close Rate',
      columnDef: 'bestMatchCloseRate30PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '30 Day Close Rate MOM',
      columnDef: 'bestMatchCloseRate30MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year 30 Day Close Rate',
      columnDef: 'bestMatchCloseRate30PreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '30 Day Close Rate YOY',
      columnDef: 'bestMatchCloseRate30YOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // 60 Day Close Rate
    {
      show: true,
      header: '60 Day Close Rate',
      columnDef: 'bestMatchCloseRate60',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month 60 Day Close Rate',
      columnDef: 'bestMatchCloseRate60PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '60 Day Close Rate MOM',
      columnDef: 'bestMatchCloseRate60MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year 60 Day Close Rate',
      columnDef: 'bestMatchCloseRate60PreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '60 Day Close Rate YOY',
      columnDef: 'bestMatchCloseRate60YOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // 90 Day Close Rate
    {
      show: true,
      header: '90 Day Close Rate',
      columnDef: 'bestMatchCloseRate90',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month 90 Day Close Rate',
      columnDef: 'bestMatchCloseRate90PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '90 Day Close Rate MOM',
      columnDef: 'bestMatchCloseRate90MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year 90 Day Close Rate',
      columnDef: 'bestMatchCloseRate90PreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '90 Day Close Rate YOY',
      columnDef: 'bestMatchCloseRate90YOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    // 120 Day Close Rate
    {
      show: true,
      header: '120 Day Close Rate',
      columnDef: 'bestMatchCloseRate120',
      isMom: false,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Month 120 Day Close Rate',
      columnDef: 'bestMatchCloseRate120PreviousMTD',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '120 Day Close Rate MOM',
      columnDef: 'bestMatchCloseRate120MOM',
      isMom: true,
      isYoy: false,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: 'Prev. Year 120 Day Close Rate',
      columnDef: 'bestMatchCloseRate120PreviousYear',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
    {
      show: false,
      header: '120 Day Close Rate YOY',
      columnDef: 'bestMatchCloseRate120YOY',
      isMom: false,
      isYoy: true,
      metricFormatKey: constants.formatKeys.percentageTwoDecimals, clickable: false
    },
  ];

  public trendTableMetrics: INameValuePair[] = [
    { name: 'Total Leads', value: 'leadVolume' },
    { name: 'Total Unique Leads', value: 'uniqueLeads' },
    { name: 'Response Rate', value: 'responseRate' },
    { name: 'Response Rate < 30 Min', value: 'responseRate30' },
    { name: 'Overall Avg. Response Time', value: 'averageResponseTime' },
    { name: 'Customer Response Rate', value: 'customerResponseRate' },
    { name: 'Appointment Scheduled Rate', value: 'appointmentRate' },
    { name: 'Appointment Showed Rate', value: 'appointmentsShowedRate' },
    { name: 'Closed Leads', value: 'bestMatchSales' },
    { name: 'Rolling Close Rate', value: 'bestMatchCloseRate' },
    { name: '30 Day Close Rate', value: 'bestMatchCloseRate30' },
    { name: '60 Day Close Rate', value: 'bestMatchCloseRate60' },
    { name: '90 Day Close Rate', value: 'bestMatchCloseRate90' },
    { name: '120 Day Close Rate', value: 'bestMatchCloseRate120' },
  ];
}
