export const environment = {
  production: false,
  hmr: false,
  debug: true,
  client: 'Audi-US',
  hostName: '',
  environmentName: 'staging',
  appName: 'Audi Lead Intelligence',
  printUser: 'print@shiftdigital.com',
  printPassword: 'ShiftDigital#1',
  ssoPassword: 'ShiftDigital#123',
  defaultChartColors: ['#4c4c4c', '#C3002F', '#A6A6A6', '#D9D9D9', '#CCCCCC'],
  applicationInsights: {
    instrumentationKey: ''
  },
  defaultLocale: 'en',
  availableLocales: ['en'],
  timeZone: 'Eastern Standard Time',
  numberOfMonthsToShowDefault: 12,
  numberOfQuartersToShowDefault: 8,
  defaultOrgLevelTypeIds: [1,3],
  closeRateLookbackMonths: 3,
  baseApiUri: 'https://audius-analytics-api-v4-staging.azurewebsites.net/api',
  authUri: 'https://audius-analytics-api-v4-staging.azurewebsites.net/token',
  baseV5ApiUri: 'https://audius-analytics-apiv5-staging.azurewebsites.net/api',
  retailerToolsIncludeAuth: false,
  retailerToolsBaseUri: '',
  mysteryShopBaseUri: '',
  favIconPath: '/assets/icons/clients/audi-us/favicon.ico',
  loginRedirectUrl: '',
  logoutRedirectUrl: '',
  sidebarDefaultOpen: false,
  useV5WebsiteOverview: false,
  v5Reports: []
};
