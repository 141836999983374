import { IDealerResourceCategoryConfig, IDealerResourcesConfig } from '../../../../_shared/models/models-index';

export class DealerResourcesConfig implements IDealerResourcesConfig {
  public reportTitle = 'Dealer Resources';
  public disclaimer = '';
  public resourceCategories: IDealerResourceCategoryConfig[] = [
    //to do - download URLs
    {
      category: 'Dealer Tooklits',
      resourceConfig: [
        {
          label: 'Audi at Your Door Level 3 Resource Guides',
          imgUrl: '../../assets/images/DealerResources/Audi/DealerResources/Audi at Your Door Level 3 Resource Guide.jpg',
          downloadUrl: 'https://audiusprod.blob.core.windows.net:443/dealer-resources/Audi_at_Your_Door_Level_3_Resource_Guide.pdf'
        },
        {
          label: 'Audi Omnichannel Retailing Toolkit Volume',
          imgUrl: '../../assets/images/DealerResources/Audi/DealerResources/Audi Omnichannel Retailing Toolkit Volume 1.jpg',
          downloadUrl: 'https://audiusprod.blob.core.windows.net:443/dealer-resources/Audi_Omnichannel_Retailing_Toolkit_Volume_1.pdf'
        }
      ]
    },
    {
      category: 'Third Party Lead Enrollment Forms',
      resourceConfig: [
          {
              label: 'Official 2022 AoA 3PL Enrollment Form',
              imgUrl: '../../assets/images/DealerResources/Audi/DealerResources/Official 2022 AoA 3PL Enrollment Agreement.jpg',
              downloadUrl: 'https://audiusprod.blob.core.windows.net/dealer-resources/Official_2022_AoA_3PL_Enrollment_Agreement.pdf'
          },
          {
            label: 'Official 2022 AoA 3PL Opt-Out Form',
            imgUrl: '../../assets/images/DealerResources/Audi/DealerResources/Official 2022 AoA 3PL Opt Out & Lead Cap Form.jpg',
            downloadUrl: 'https://audiusprod.blob.core.windows.net/dealer-resources/Official_2022_AoA_3PL_Opt_Out_Lead_Cap_Form.pdf'
          }
        ]
    }
  ];
}

