import {
  IDefaultTableColumnConfig,
  IDigitalRetailingOverviewBillboardConfig,
  IDigitalRetailingOverviewConfig,
  IDualAxisLineGraphConfig,
  IMultiLevelDualAxisLineGraphConfig
} from '../../../../_shared/models/models-index';
import * as constants from '../../../../_shared/constants/constants';
import { Enums } from '../../../../_shared/enums/enums';

export class DigitalRetailingOverviewConfig implements IDigitalRetailingOverviewConfig {
  public useV5 = false;
  public reportTitle = 'Online Engagement';
  public dealerTableLabel = 'Retailer Summary';
  public metricDisplayModes = [
    { name: 'MOM', value: 'MOM' },
    { name: 'YOY', value: 'YOY' }
  ];
  public showEngagementAnalysis = true;
  public useDefaultComparisonColumns = true;
  public chartSeriesColors = ['#4c4c4c', '#C3002F', '#A6A6A6'];
  public billboards: IDigitalRetailingOverviewBillboardConfig[] = [
  {
    title: 'Session Starts',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'sessionStarts',
    metricMOMPropertyName: 'sessionStartsMOM',
    metricYOYPropertyName: 'sessionStartsYOY',
    metricPreviousMonthPropertyName: 'sessionStartsPreviousMonth',
      metricPreviousMTDPropertyName: 'sessionStartsPreviousMTD',
      metricPreviousMTDDisplayOnCurrentMonth: true,
      metricPreviousMTDDisplayHistorical: false,
      metricPreviousMTDLabel: 'Prev. MTD',
      metricPreviousMonthDisplayOnCurrentMonth: true,
      metricPreviousMonthDisplayHistorical: true,
      metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'sessionStartsMOM',
    metricFormatKey: constants.formatKeys.localeString,
    helpTextKey: 'DigitalRetailingOverviewSessionStartsBillboardHelp',
    helpTextTitle: 'Session Starts',
  },
  {
    title: 'Session Start Rate',
    subtitle: null,
    iconClass: 'fa-users fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'sessionStartRate',
    metricMOMPropertyName: 'sessionStartRateMOM',
    metricYOYPropertyName: 'sessionStartRateYOY',
    metricPreviousMonthPropertyName: 'sessionStartRatePreviousMonth',
    metricPreviousMTDPropertyName: 'sessionStartRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'sessionStartRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewSessionStartRateBillboardHelp',
    helpTextTitle: 'Session Start Rate',
  },
  {
    title: 'Leads Captured',
    subtitle: null,
    iconClass: 'fa-user-circle fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'leadCaptures',
    metricMOMPropertyName: 'leadCapturesMOM',
    metricYOYPropertyName: 'leadCapturesYOY',
    metricPreviousMonthPropertyName: 'leadCapturesPreviousMonth',
    metricPreviousMTDPropertyName: 'leadCapturesPreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'leadCapturesMOM',
    metricFormatKey: constants.formatKeys.localeString,
    helpTextKey: 'DigitalRetailingOverviewLeadCapturedBillboardHelp',
    helpTextTitle: 'Lead Capture Rate',
  },
  {
    title: 'Leads Capture Rate',
    subtitle: null,
    iconClass: 'fa-user-circle fa-primary',
    reverseMetric: false,
    metricCurrentPropertyName: 'leadCaptureRate',
    metricMOMPropertyName: 'leadCaptureRateMOM',
    metricYOYPropertyName: 'leadCaptureRateYOY',
    metricPreviousMonthPropertyName: 'leadCaptureRatePreviousMonth',
    metricPreviousMTDPropertyName: 'leadCaptureRatePreviousMTD',
    metricPreviousMTDDisplayOnCurrentMonth: true,
    metricPreviousMTDDisplayHistorical: false,
    metricPreviousMTDLabel: 'Prev. MTD',
    metricPreviousMonthDisplayOnCurrentMonth: true,
    metricPreviousMonthDisplayHistorical: true,
    metricPreviousMonthLabel: 'Prev. Month',
    metricPreviousYearPropertyName: null,
    footerRowCount: 1,
    footerTwoLeftLabel: 'MOM',
    footerTwoRightLabel: 'YOY',
    footerTwoMetricFormatKey: constants.formatKeys.percentageTwoDecimals,
    showTrendArrow: true,
    trendArrowDrivingPropertyName: 'leadCaptureRateMOM',
    metricFormatKey: constants.formatKeys.percentageTwoDecimals,
    helpTextKey: 'DigitalRetailingOverviewLeadCaptureRateBillboardHelp',
    helpTextTitle: 'Lead Capture Rate',
  },];
  public kpiSheetOptions = {
    overview: [{
      name: 'providerId',
      label: 'Provider',
      isHeader: true
    },  {
      name: 'sessionStarts',
      label: 'Sessions Started',
    }, {
      name: 'leadCaptureRate',
      label: 'Lead Capture Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'tradeInRate',
      label: 'Trade-In Rate',
        format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'paymentCalcRate',
      label: 'Payment Calc Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'creditAppRate',
      label: 'Credit App Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
    //  name: 'preQualifiedRate',
    //  label: 'Pre-Qualified Rate',
    //  format: constants.formatKeys.percentageTwoDecimals
    //}, {
      name: 'financeFinishRate',
      label: 'F & I Complete Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }, {
      name: 'appointmentRate',
      label: 'Appointment Rate',
      format: constants.formatKeys.percentageTwoDecimals
    }]
  };
  public trendGraphConfig: IMultiLevelDualAxisLineGraphConfig = {
    xAxisPropertyName: 'date',
    parentMetrics: [

      {
        id: Enums.digitalRetailingMetrics.sessionStarts.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStarts.name,
        propertyName: Enums.digitalRetailingMetrics.sessionStarts.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.sessionStartRate.metricId,
        displayName: Enums.digitalRetailingMetrics.sessionStartRate.name,
        propertyName: Enums.digitalRetailingMetrics.sessionStartRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.leadCaptures.metricId,
        displayName: 'Leads Captured',
        propertyName: Enums.digitalRetailingMetrics.leadCaptures.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.leadCaptureRate.metricId,
        displayName: 'Lead Capture Rate',
        propertyName: Enums.digitalRetailingMetrics.leadCaptureRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.tradeIns.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeIns.name,
        propertyName: Enums.digitalRetailingMetrics.tradeIns.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.tradeInRate.metricId,
        displayName: Enums.digitalRetailingMetrics.tradeInRate.name,
        propertyName: Enums.digitalRetailingMetrics.tradeInRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.paymentCalculators.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculators.name,
        propertyName: Enums.digitalRetailingMetrics.paymentCalculators.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.paymentCalculatorRate.metricId,
        displayName: Enums.digitalRetailingMetrics.paymentCalculatorRate.name,
        propertyName: Enums.digitalRetailingMetrics.paymentCalculatorRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.creditApps.metricId,
        displayName: Enums.digitalRetailingMetrics.creditApps.name,
        propertyName: Enums.digitalRetailingMetrics.creditApps.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.creditAppRate.metricId,
        displayName: Enums.digitalRetailingMetrics.creditAppRate.name,
        propertyName: Enums.digitalRetailingMetrics.creditAppRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.financeFinishes.metricId,
        displayName: 'F & I Completes',
        propertyName: Enums.digitalRetailingMetrics.financeFinishes.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      },
      {
        id: Enums.digitalRetailingMetrics.financeFinishRate.metricId,
        displayName: 'F & I Complete Rate',
        propertyName: Enums.digitalRetailingMetrics.financeFinishRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      },
      {
        id: Enums.digitalRetailingMetrics.appointments.metricId,
        displayName: Enums.digitalRetailingMetrics.appointments.name,
        propertyName: Enums.digitalRetailingMetrics.appointments.nameKey,
        metricFormatKey: constants.formatKeys.roundToInteger
      }, {
        id: Enums.digitalRetailingMetrics.appointmentRate.metricId,
        displayName: Enums.digitalRetailingMetrics.appointmentRate.name,
        propertyName: Enums.digitalRetailingMetrics.appointmentRate.nameKey,
        metricFormatKey: constants.formatKeys.percentageTwoDecimals
      }
    ],
    childMetrics: [
      {
        id: Enums.chatProviders.allProviders.providerId,
        displayName: Enums.chatProviders.allProviders.displayName,
        propertyName: Enums.chatProviders.allProviders.name,
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 15,
        displayName: 'CarNow',
        propertyName: 'CarNow',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 13,
        displayName: 'Darwin',
        propertyName: 'Darwin',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 4,
        displayName: 'Dealer.com',
        propertyName: 'Dealer.com',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 10,
        displayName: 'Gubagoo',
        propertyName: 'Gubagoo',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 11,
        displayName: 'Modal',
        propertyName: 'Modal',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 14,
        displayName: 'Roadster',
        propertyName: 'Roadster',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 12,
        displayName: 'TagRail',
        propertyName: 'TagRail',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 17,
        displayName: 'Tekion',
        propertyName: 'Tekion',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 16,
        displayName: 'Upstart',
        propertyName: 'Upstart',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
      {
        id: 15,
        displayName: 'WebBuy',
        propertyName: 'WebBuy',
        metricFormatKey: constants.formatKeys.entityDisplayName
      },
    ],
    defaultChildrenIds: [Enums.chatProviders.allProviders.providerId, Enums.chatProviders.allProviders.providerId],
    defaultParentIds: [Enums.digitalRetailingMetrics.sessionStarts.metricId, Enums.digitalRetailingMetrics.appointmentRate.metricId],
    metricOneColorHexCode: '#ba0a31',
    metricTwoColorHexCode: '#3a3a3a'
  };
  public dealerTableColumns: IDefaultTableColumnConfig[] = [
    {
      show: true,
      header: '',
      columnDef: 'entity',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: true
    }, {
      show: false,
      print: true,
      header: 'Retailer Name',
      columnDef: 'displayName',
      metricFormatKey: constants.formatKeys.entityDisplayName,
      clickable: false
    },
    {
      show: true,
      header: 'Session Starts',
      columnDef: 'sessionStarts',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Session Start Rate',
      columnDef: 'sessionStartRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Leads Captured',
      columnDef: 'leadCaptures',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Lead Capture Rate',
      columnDef: 'leadCaptureRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Trade-Ins',
      columnDef: 'tradeIns',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    {
      show: true,
      header: 'Trade-In Rate',
      columnDef: 'tradeInRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Payment Calculators
    {
      show: true,
      header: 'Payment Calculators',
      columnDef: 'paymentCalcs',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Payment Calculator Rate
    {
      show: true,
      header: 'Payment Calculator Rate',
      columnDef: 'paymentCalcRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Credit Apps
    {
      show: true,
      header: 'Credit Apps',
      columnDef: 'creditApps',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Credit App Rate
    {
      show: true,
      header: 'Credit App Rate',
      columnDef: 'creditAppRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // F & I Completions
    {
      show: true,
      header: 'F & I Completes',
      columnDef: 'financeFinishes',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // F & I Completions Rate
    {
      show: true,
      header: 'F & I Complete Rate',
      columnDef: 'financeFinishRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointments
    {
      show: true,
      header: 'Appointments',
      columnDef: 'appointments',
      metricFormatKey: constants.formatKeys.localeString,
      clickable: false,
      hasComparisonMetrics: true
    },
    // Appointment Rate
    {
      show: true,
      header: 'Appointment Rate',
      columnDef: 'appointmentRate',
      metricFormatKey: constants.formatKeys.percentageTwoDecimals,
      clickable: false,
      hasComparisonMetrics: true
    }
  ];
}
