import { KpiSheetColumn } from '../../../../_shared/components/kpi-sheet/kpi-sheet-models';
import {
    IWebsiteOverviewBillboardConfig,
    IWebsiteOverviewConfig,
    IDefaultTableColumnConfig,
    IWebsiteOverviewKpiConfig,
    ICardHeaderConfig,
    KpiSheetOptions,} from '../../../../_shared/models/models-index';

export class WebsiteOverviewConfig implements IWebsiteOverviewConfig {
    public reportTitle: string;
    public useNewMetricCutoffDate: boolean;
    public useDefaultComparisonColumns: boolean = true;
    public newMetricCutoffDate?: Date;
    public showPieChart: boolean = true;
    public billboards: IWebsiteOverviewBillboardConfig[] = [];
    public dealerBenchmarkColumns: IDefaultTableColumnConfig[] = [];
    public kpiCardConfig: IWebsiteOverviewKpiConfig = null;
    public chartSeriesColors: string[] = [];
    public referrerTypeColors: string[] = [];
    public showReferrerTypeRateMetric?: boolean;
    public deviceTypeColors: string[] = [];
    public referrerQualityColors: string[] = [];
    public showReferrerQualityRate?: boolean;
    public benchmarkTitle: string;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public referrerQualityCardHeader: ICardHeaderConfig = null;
    public referrerTypeCardHeader: ICardHeaderConfig = null;
    public trafficByDeviceTypeHeader: ICardHeaderConfig = null;
    public visitorTrendCardHeader: ICardHeaderConfig = null;
    public kpiSheetOptions: Record<KpiSheetOptions, KpiSheetColumn[]> = null;
    public reverseBounceRateTrendArrow: boolean;
}
