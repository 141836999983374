import { OrgEntityType } from '../filter/filter.model';

export type DateMonthModes = 'calendar' | 'sales';
export type DateMonthModeList = Record<DateMonthModes, number>;
export const dateMonthModeLookup: DateMonthModeList = {
  'calendar': 1,
  'sales': 2
};

export type DateScenarioList = Record<IDateModeEnumKeys, { dateScenarioId: number, displayName: string }>;

export interface IReportingVehicleMakeEnum {
  mercedesBenz: string;
  smart: string;
  sprinter: string;
  freightLiner: string;
}

export interface IReportingVehicleModelEnum {
  allModels: string;
  cadenza: string;
  forte: string;
  k900: string;
  niro: string;
  niroPHEV: string;
  niroPluginHybrid: string;
  optima: string;
  optimaHybrid: string;
  optimaPHEV: string;
  optimaPluginHybrid: string;
  rio: string;
  rio5door: string;
  sedona: string;
  sorento: string;
  soul: string;
  soulEv: string;
  sportage: string;
  stinger: string;
}

export interface IReportingVehicleClassEnum {
  allClasses: string;
}

export interface IAccountType {
  displayName: string;
  nameForFilter: string;
}

export interface IAccountTypeEnum {
  orv: IAccountType;
  snow: IAccountType;
}

export interface ICacheKeys {
  authData: string;
  rememberMe: string;
  currentFilter: string;
  defaultFilter: string;
  salesUnit: string;
  orgLookups: string;
  session: string;
}

export interface IChannelProperty {
  channelId: number;
  channelTypeId: number;
  channelName: string;
  channelColor: string;
  mediaTypeId: number;
}

export interface IChannelType {
  channelTypeId: number;
  name: string;
}

export interface IChannelTypeEnum {
  organic: IChannelType;
  paid: IChannelType;
}

export interface IChannelEnum {
  allChannels: IChannelProperty;
  brand: IChannelProperty;
  paidSearch: IChannelProperty;
  organicSearch: IChannelProperty;
  paidDisplay: IChannelProperty;
  paidVideo: IChannelProperty;
  paidEmail: IChannelProperty;
  organicEmail: IChannelProperty;
  paidSocial: IChannelProperty;
  organicSocial: IChannelProperty;
  thirdPartySites: IChannelProperty;
  typedBookmarked: IChannelProperty;
  other: IChannelProperty;
  groupSites: IChannelProperty;
  travelSites: IChannelProperty;
  ldaSearch: IChannelProperty;
}

export interface IChartColorEnum {
  color1: string;
  color2: string;
  color3: string;
  color4: string;
  color5: string;
}

export interface IChatMetricEnum {
  impressions: IMetricType;
  chatsStarted: IMetricType;
  chatStartRate: IMetricType;
  chatsAbandoned: IMetricType;
  chatAbandonRate: IMetricType;
  chatsEngaged: IMetricType;
  chatEngagementRate: IMetricType;
  avgMessagesPerChat: IMetricType;
  chatLeads: IMetricType;
  chatConversionRate: IMetricType;
  dealerEnrollments: IMetricType;
  enrollments: IMetricType;
  visitsWithImpressions: IMetricType;
}

export interface IChatProviderEnum {
  allProviders: IMetricProvider;
  activEngage: IMetricProvider;
  autoLeadStar: IMetricProvider;
  carNow: IMetricProvider;
  carChat24: IMetricProvider;
  conversations: IMetricProvider;
  dealereProcess: IMetricProvider;
  digitalAirStrike: IMetricProvider;
  edmunds: IMetricProvider;
  gubagoo: IMetricProvider;
  livePerson: IMetricProvider;
  podium: IMetricProvider;
  dealerInspire: IMetricProvider;
  fusionZone: IMetricProvider;
  kenect: IMetricProvider;
}

export interface IGuageColorEnum {
  green: string;
  yellow: string;
  red: string;
}

export interface ICloseRate {
  closeRateId: number;
  name: string;
}

export interface ICloseRateEnum {
  ninetyDay: ICloseRate;
  sixtyDay: ICloseRate;
  thirtyDay: ICloseRate;
}

export interface IChannelMetricEnum {
  visits: number;
  pageViews: number;
}

export interface IDataTableType {
  hierarchy: string;
  hierarchyV5: string;
  pagedSorted: string;
  trend: string;
  billboard: string;
}

export interface IDateMode {
  dateModeId: number;
  displayName: string;
}

export interface IDateModeEnum {
  lastThirtyDays: IDateMode;
  lastMonth: IDateMode;
  currentMonth: IDateMode;
  previousThreeMonths: IDateMode;
  previousTwelveMonths: IDateMode;
  previousThirteenMonths: IDateMode;
  specificMonthOption: IDateMode;
  quarterly: IDateMode;
  custom: IDateMode;
  yearToDate: IDateMode;
}
export type IDateModeEnumKeys = keyof IDateModeEnum;

export interface IDealerPerformanceGraphViewEnum {
  region: IGraphView;
  dealer: IGraphView;
  total: IGraphView;
}

export interface IDemandPerformanceReportViewEnum {
  dealerPerformance: IGraphView;
  sourcePerformance: IGraphView;
  newInventoryByDealer: IGraphView;
  newInventoryByBrand: IGraphView;
  usedInventoryByDealer: IGraphView;
}

export interface IDemandScenario {
  id: number;
  name: string;
}

export interface IDemandScenarioEnum {
  total: IDemandScenario;
  leads: IDemandScenario;
  traffic: IDemandScenario;
  newInventory: IDemandScenario;
  usedInventory: IDemandScenario;
}

export interface IDemandScenarioMetric {
  id: number;
  name: string;
  scenarioId: number;
}

export interface IDemandScenarioMetricEnum {
  none: IDemandScenarioMetric;
  totalLeads: IDemandScenarioMetric;
  totalLeadCloseRate: IDemandScenarioMetric;
  newLeads: IDemandScenarioMetric;
  newLeadCloseRate: IDemandScenarioMetric;
  usedLeads: IDemandScenarioMetric;
  usedLeadCloseRate: IDemandScenarioMetric;
  totalVisits: IDemandScenarioMetric;
  uniqueVisitors: IDemandScenarioMetric;
  engagedVisits: IDemandScenarioMetric;
  engagementRate: IDemandScenarioMetric;
  salesPageViews: IDemandScenarioMetric;
  salesPageViewsPerVisit: IDemandScenarioMetric;
  actions: IDemandScenarioMetric;
  actionRate: IDemandScenarioMetric;
  newTotalVins: IDemandScenarioMetric;
  newVdpsPerVin: IDemandScenarioMetric;
  newDaysOnLot: IDemandScenarioMetric;
  newHealthScore: IDemandScenarioMetric;
  usedTotalVins: IDemandScenarioMetric;
  usedVdpsPerVin: IDemandScenarioMetric;
  usedDaysOnLot: IDemandScenarioMetric;
  usedHealthScore: IDemandScenarioMetric;
}

export interface IDemandScenarioGroup {
  id: number;
  name: string;
  scenarioId: number;
  nameKey: string;
}

export interface IDemandScenarioGroupEnum {
  leadTotal: IDemandScenarioGroup;
  leadSourceCategory: IDemandScenarioGroup;
  leadSourceType: IDemandScenarioGroup;
  leadRegion: IDemandScenarioGroup;
  leadDealer: IDemandScenarioGroup;
  trafficTotal: IDemandScenarioGroup;
  trafficMediaType: IDemandScenarioGroup;
  trafficChannel: IDemandScenarioGroup;
  trafficRegion: IDemandScenarioGroup;
  trafficDealer: IDemandScenarioGroup;
  newInventoryTotal: IDemandScenarioGroup;
  newInventoryMake: IDemandScenarioGroup;
  newInventoryRegion: IDemandScenarioGroup;
  newInventoryDealer: IDemandScenarioGroup;
  usedInventoryTotal: IDemandScenarioGroup;
  usedInventoryRegion: IDemandScenarioGroup;
  usedInventoryDealer: IDemandScenarioGroup;
}

export interface IDeviceType {
  deviceTypeId: number;
  name: string;
}

export interface IDeviceTypeEnum {
  desktop: IDeviceType;
  phone: IDeviceType;
  tablet: IDeviceType;
}
export interface IDeviceTypeGraphViewEnum {
  allDevices: IGraphView;
  desktop: IGraphView;
  phone: IGraphView;
  tablet: IGraphView;
  total: IGraphView;
}

export interface IDeviceTypeMetricEnum {
  visits: number;
  pageViews: number;
}

export interface IDigitalAdvertisingChannelEnum {
  allChannels: IChannelProperty;
  search: IChannelProperty;
  display: IChannelProperty;
  video: IChannelProperty;
  social: IChannelProperty;
  email: IChannelProperty;
}

export interface IExternalLink {
  name: string;
  url: string;
}

// This interface is used when generating DDLs for toggling which data is going to be shown, whether that is a graph/chart or a table
export interface IGraphView {
  viewId: number;
  displayName: string;
  altDisplayName?: string;
}

export interface ILeadType {
  leadTypeId: number;
  name: string;
}

export interface ILeadTypeEnum {
  afterSales: ILeadType;
  new: ILeadType;
  cpo: ILeadType;
  finance: ILeadType;
  used: ILeadType;
}

export interface IMediaType {
  mediaTypeId: number;
  name: string;
}

export interface IMediaTypeEnum {
  search: IMediaType;
  display: IMediaType;
  video: IMediaType;
  social: IMediaType;
  displayRetargeting: IMediaType;
  socialRetargeting: IMediaType;
  videoRetargeting: IMediaType;
}

export interface IMetricProvider {
  providerId: number;
  name: string;
  displayName: string;
  code: string;
}

export interface ICertifiedChatProviderEnum {
  allProviders: IMetricProvider;
  activeEngage: IMetricProvider;
  carChat24: IMetricProvider;
  carNow: IMetricProvider;
  contactAtOnce: IMetricProvider;
  gubagoo: IMetricProvider;
}

export interface IDigitalAdvertisingProviderEnum {
  allProviders: IMetricProvider;
  constellation: IMetricProvider;
  dealerFire: IMetricProvider;
  dealerInspire: IMetricProvider;
  dealerOn: IMetricProvider;
  dealerDotCom: IMetricProvider;
  dominion: IMetricProvider;
  ebizAutos: IMetricProvider;
  cdk: IMetricProvider;
  pixelMotion: IMetricProvider;
  pureCars: IMetricProvider;
  stream: IMetricProvider;
  teamVelocity: IMetricProvider;
  nerdAuto: IMetricProvider;
  magnetis: IMetricProvider;
  dealerTeamwork: IMetricProvider;
  forceMarketing: IMetricProvider;
  shiftDigital: IMetricProvider;
  dealerSpike: IMetricProvider;
  allAutoNetwork: IMetricProvider;
}

export interface IDigitalRetailingMetricEnum {
  none: IMetricType;
  sessionStarts: IMetricType;
  sessionStartRate: IMetricType;
  uniqueShoppers: IMetricType;
  visits: IMetricType;
  visitRate: IMetricType;
  visitors: IMetricType;
  visitorRate: IMetricType;
  leadCaptures: IMetricType;
  leadCaptureRate: IMetricType;
  tradeIns: IMetricType;
  tradeInRate: IMetricType;
  financeFinishes: IMetricType;
  financeFinishRate: IMetricType;
  preQualifieds: IMetricType;
  preQualifiedRate: IMetricType;
  creditApps: IMetricType;
  creditAppRate: IMetricType;
  allCreditApps: IMetricType;
  allCreditAppRate: IMetricType;
  appointments: IMetricType;
  appointmentRate: IMetricType;
  paymentCalculators: IMetricType;
  paymentCalculatorRate: IMetricType;
  paymentCalcStarts: IMetricType;
  paymentCalcStartRate: IMetricType;
  //paymentCalculatorStartRate: IMetricType;
  formSubmissions: IMetricType;
  formSubmissionRate: IMetricType;
  eContractingRate: IMetricType;
  eContracting: IMetricType;
  depositRate: IMetricType;
  deposits: IMetricType;
  incentives: IMetricType;
  incentiveRate: IMetricType;
  accessories: IMetricType;
  accessoriesRate: IMetricType;
}

export interface IDigitalRetailingProviderEnum {
  allProviders: IMetricProvider;
  autoFi: IMetricProvider;
  carNow: IMetricProvider;
  cdkGlobal: IMetricProvider;
  darwin: IMetricProvider;
  dealerDotCom: IMetricProvider;
  dep: IMetricProvider;
  dealerInspire: IMetricProvider;
  dealerOn: IMetricProvider;
  dealerFire: IMetricProvider;
  gubagoo: IMetricProvider;
  modal: IMetricProvider;
  perq: IMetricProvider;
  prodigy: IMetricProvider;
  roadster: IMetricProvider;
  tagRail: IMetricProvider;
  joydrive: IMetricProvider;
}

export interface ITradeInMetricEnum {
  none: IMetricType;
  impressions: IMetricType;
  visitsWithImpressions: IMetricType;
  starts: IMetricType;
  startRate: IMetricType;
  vehicleIndicateds: IMetricType;
  vehicleIndicatedRate: IMetricType;
  formInitiations: IMetricType;
  formInitiationRate: IMetricType;
  formSubmissions: IMetricType;
  formSubmissionRate: IMetricType;
  appointments: IMetricType;
  appointmentRate: IMetricType;
  completions: IMetricType;
  completionRate: IMetricType;
  leadVolumn: IMetricType;
  conversionRate: IMetricType;
  dealerEnrollments: IMetricType;
  enrollments: IMetricType;
}

export interface IExternalLinkEnum {
  mysteryShop: IExternalLink;
}

export interface IHierarchicalEntityTypeEnum {
  dealer: IHierarchicalEntityType;
  lma: IHierarchicalEntityType;
  orgcode1: IHierarchicalEntityType;
  orgcode2: IHierarchicalEntityType;
  orgcode3: IHierarchicalEntityType;
  orgcode4: IHierarchicalEntityType;
  orgcode5: IHierarchicalEntityType;
  national: IHierarchicalEntityType;
}

export interface IVehicleEntityTypeEnum {
  model: IVehicleEntityType;
  class: IVehicleEntityType;
  allClasses: IVehicleEntityType;
}

export interface IHierarchicalEntityType {
  type: string;
  displayName: string;
}

export interface IVehicleEntityType {
  type: string;
  displayName: string;
}

export interface IReputationManagementProviderEnum {
  allProviders: IMetricProvider;
  friendemic: IMetricProvider;
  digitalAirStrike: IMetricProvider;
  podium: IMetricProvider;
}

export interface ISocialMediaProviderEnum {
  allProviders: IMetricProvider;
  friendemic: IMetricProvider;
  digitalAirStrike: IMetricProvider;
}

export interface IWebsiteProviderEnum {
  allProviders: IMetricProvider;
  activEngage: IMetricProvider;
  autoLoop:IMetricProvider;
  carChat24:IMetricProvider;
  carNow: IMetricProvider;
  cdk: IMetricProvider;
  conversations: IMetricProvider;
  dealerDotCom: IMetricProvider;
  dealerEProcess: IMetricProvider;
  dealerFire: IMetricProvider;
  dealerInspire: IMetricProvider;
  dealerOn: IMetricProvider;
  dealerVenom: IMetricProvider;
  digitalAirStrike: IMetricProvider;
  dom360: IMetricProvider;
  eBizAutos: IMetricProvider;
  eDealer: IMetricProvider;
  edmunds: IMetricProvider;
  foxDealer: IMetricProvider;
  fusionZone: IMetricProvider;
  gubagoo: IMetricProvider;
  livePerson: IMetricProvider;
  podium: IMetricProvider;
  searchOptics: IMetricProvider;
  sm360: IMetricProvider;
  strathcom: IMetricProvider;
  stream: IMetricProvider;
  tradePending: IMetricProvider;
  pixelMotion: IMetricProvider;
  allAutoNetwork: IMetricProvider;
  agency360: IMetricProvider;
  nerdAuto: IMetricProvider;
  magnetis: IMetricProvider;
  autoTrader: IMetricProvider;
  d2cMedia: IMetricProvider;
  ezResults: IMetricProvider;
  sincro: IMetricProvider;
  teamVelocity: IMetricProvider;
  nabThat: IMetricProvider;
  dealerSpike: IMetricProvider;
  dx1: IMetricProvider;
}

export interface IPrintingOptionEnum {
  pdf: IPrintingOption;
  excel: IPrintingOption;
}

export interface IPrintingOption {
  id: number;
  name: string;
}

export interface IReferrerQualityGraphViewEnum {
  channel: IGraphView;
  mediaType: IGraphView;
  total: IGraphView;
}

export interface IReportDateMode {
  reportDateModeId: number;
  name: string;
}

export interface IReportDateModeEnum {
  months: IReportDateMode;
  days: IReportDateMode;
}

export interface IRouteNames {
  dealerDashboard: string;
  dealerPerformanceReport: string;
  digitalAdvertisingReport: string;
  districtDashboard: string;
  leadSummary: string;
  regionDashboard: string;
  salesDashboard: string;
  salesSummary: string;
  salesVehicle: string;
  sourcePerformanceReport: string;
  summaryReport: string;
  transactionsReport: string;
}

export interface IRouteCategories {
  digAd: string;
  leads: string;
  management: string;
  sales: string;
  website: string;
}

export interface ISalesUnitEnum {
  orv: ISalesUnit;
  motorcycle: ISalesUnit;
  commercial: ISalesUnit;
}

export interface ISalesUnit {
  salesUnitId: number;
  name: string;
}

export interface IOrgDateCode {
  orgDateCodeId: number;
  name: string;
}

export interface IOrgDateCodeEnum {
  calendar: IOrgDateCode;
  orgDateCode2: IOrgDateCode;
  orgDateCode3: IOrgDateCode;
}

export interface IOrgLookupType {
  orgLookupTypeId: number;
  name: string;
  orgEntityType: OrgEntityType;
  default?: boolean;
}

export interface IVehicleLookupType {
  vehicleLookupTypeId: number;
  name: string;
}

export interface IOrgLookupTypeEnum {
  national: IOrgLookupType;
  lma: IOrgLookupType;
  virtual20: IOrgLookupType;
  dealerGroups: IOrgLookupType;
}

export interface IPageType {
  name: string;
  pageTypeId: number;
}

export interface IPageTypeEnum {
  leads: IPageType;
  sales: IPageType;
  digitalRetailing: IPageType;
}

export interface IVehicleLookupTypeEnum {
  vehicleClass: IVehicleLookupType;
  vehicleModel: IVehicleLookupType;
}

export interface ISaleType {
  saleTypeId: number;
  name: string;
}

export interface ISaleTypeEnum {
  new: ISaleType;
  used: ISaleType;
  cpo: ISaleType;
  other: ISaleType;
}

export interface ISourceType {
  sourceTypeId: number;
  name: string;
}

export interface ISourceTypeEnum {
  all: ISourceType;
  mbusadotcom: ISourceType;
  brand: ISourceType;
  thirdParty: ISourceType;
  dealerWebsite: ISourceType;
  inventory: ISourceType;
  event: ISourceType;
  dealerGenerated: ISourceType;
}

export interface IDispositionActivity {
  dispositionActivityId: number;
  name: string;
}

export interface IDispositionActivityEnum {
  appointment: IDispositionActivity;
}

export interface ILeadDesignation {
  leadDesignationId: number;
  name: string;
}

export interface ILeadDesignationEnum {
  sentToDealer: ILeadDesignation;
}

export interface ITrafficLevelTwoReportMode {
  reportModeId: number;
  name: string;
  metricOneDefault: IMetricType;
  metricTwoDefault: IMetricType;
}

export interface ITrafficLevelTwoReportModeEnum {
  uniqueVisitorsBillboard: ITrafficLevelTwoReportMode;
  visitsBillboard: ITrafficLevelTwoReportMode;
  actionsBillboard: ITrafficLevelTwoReportMode;
  engagementBillboard: ITrafficLevelTwoReportMode;
  channelBarchart: ITrafficLevelTwoReportMode;
  trafficTrend: ITrafficLevelTwoReportMode;
  // vdpsTrend: ITrafficLevelTwoReportMode;
  // serviceTrend: ITrafficLevelTwoReportMode;
  referrerQuality: ITrafficLevelTwoReportMode;
  trafficByDeviceType: ITrafficLevelTwoReportMode;
  dealerPerformance: ITrafficLevelTwoReportMode;
  campaignPerformance: ITrafficLevelTwoReportMode;
}

export interface IMetricType {
  metricId: number;
  name: string;
  nameKey?: string;
  disabled?: boolean;
}

export interface IDigitalAdvertisingMetricEnum {
  none: IMetricType;
  actionRate: IMetricType;
  actualCostPerAcquisition: IMetricType;
  actualCostPerClick: IMetricType;
  actualCostPerImpression: IMetricType;
  bounceRate: IMetricType;
  budgetCostPerAcquisition: IMetricType;
  budgetCostPerClick: IMetricType;
  budgetCostPerImpression: IMetricType;
  calculatedBudgetCostPerAcquisition: IMetricType;
  calculatedBudgetCostPerClick: IMetricType;
  calculatedBudgetCostPerImpression: IMetricType;
  chatRate: IMetricType;
  chats: IMetricType;
  clicks: IMetricType;
  clickThroughRate: IMetricType;
  completedVideoViews: IMetricType;
  completions: IMetricType;
  completionRate: IMetricType;
  conversionRate: IMetricType;
  engagementRate: IMetricType;
  formSubmissions: IMetricType;
  impressions: IMetricType;
  partOrders: IMetricType;
  phoneCalls: IMetricType;
  providerBounceRate: IMetricType;
  providerFormSubmissions: IMetricType;
  providerTotalVisits: IMetricType;
  providerUniqueVisitors: IMetricType;
  serviceSchedulers: IMetricType;
  socialFrequency: IMetricType;
  socialReach: IMetricType;
  taggingBounceRate: IMetricType;
  taggingFormSubmissions: IMetricType;
  taggingTotalVisits: IMetricType;
  taggingUniqueVisitors: IMetricType;
  videoViews: IMetricType;
  videoViewRate: IMetricType;
  totalVisits: IMetricType;
  uniqueVisitors: IMetricType;
  qualifiedVisits: IMetricType;
  vlpViews: IMetricType;
  vdpViews: IMetricType;
  bounces: IMetricType;
  midpointViews: IMetricType;
  completedVideoViewRate: IMetricType;
  actualCostPerAction: IMetricType;
  enrollment: IMetricType;
  percentageOfTotalEnrollment: IMetricType;
  avgImpressions: IMetricType;
  avgClickThroughRate: IMetricType;
  avgEngagementRate: IMetricType;
  avgActionRate: IMetricType;
}

export interface IFacebookLeadMetricEnum {
  none: IMetricType;
  leadCount: IMetricType;
  saleCount: IMetricType;
  conversionRate: IMetricType;
}

export interface ILeadMetricEnum {
  none: IMetricType;
  leadVolume: IMetricType;
  nabrLeadVolume: IMetricType;
  brandNabrLeads: IMetricType;
  responseRate: IMetricType;
  responseRate180: IMetricType;
  responseRate120: IMetricType;
  responseRate60: IMetricType;
  responseRate45: IMetricType;
  responseRate40: IMetricType;
  responseRate30: IMetricType;
  responseRate15: IMetricType;
  leadSales: IMetricType;
  closeRate180: IMetricType;
  closeRate150: IMetricType;
  closeRate120: IMetricType;
  closeRate90: IMetricType;
  closeRate60: IMetricType;
  closeRate30: IMetricType;
  closeRate: IMetricType;
  gainedSales: IMetricType;
  lostSales: IMetricType;
  percentOfLeads: IMetricType;
  percentOfSales: IMetricType;
  conversionRate: IMetricType;
  conversionRate30: IMetricType;
  conversionRate60: IMetricType;
  conversionRate90: IMetricType;
  conversionRate120: IMetricType;
  uniqueLeads: IMetricType;
  attributedCloseRate: IMetricType;
  averageResponseTime: IMetricType;
  averageResponseTime48Hour: IMetricType;
  mbUsaDotComLeads: IMetricType;
  otherBrandLeads: IMetricType;
  dealerWebsiteLeads: IMetricType;
  marketingLeads: IMetricType;
  eventLeads: IMetricType;
  thirdPartyLeads: IMetricType;
  appointmentRate: IMetricType;
  archivedLeadVolume: IMetricType;
  archivedLeadSales: IMetricType;
  archivedConversionRate: IMetricType;
  averageDailyVolume: IMetricType;
  brandSiteLeads: IMetricType;
  dealerGeneratedLeads: IMetricType;
  digitalRetailLeads: IMetricType;
  leadVolumePma: IMetricType;
  leadVolumeNonPma: IMetricType;
  leadSalesPma: IMetricType;
  leadSalesNonPma: IMetricType;
  closeRatePma: IMetricType;
  closeRateNonPma: IMetricType;
  conversionRatePma: IMetricType;
  conversionRateNonPma: IMetricType;
  responseRatePma: IMetricType;
  responseRateNonPma: IMetricType;
  responseRate60Pma: IMetricType;
  responseRate60NonPma: IMetricType;
  appointmentRatePma: IMetricType;
  appointmentRateNonPma: IMetricType;
  bestMatchSales: IMetricType;
  bestMatchCloseRate: IMetricType;
  bestMatchCloseRate30: IMetricType;
  bestMatchCloseRate60: IMetricType;
  bestMatchCloseRate90: IMetricType;
  bestMatchCloseRate120: IMetricType;
  bestMatchCloseRate150: IMetricType;
  bestMatchCloseRate180: IMetricType;
  lostDealerSales: IMetricType;
  averageCommunications: IMetricType;
}

export interface IInventoryMetricEnum {
  inventory: IMetricType;
  daysSupply: IMetricType;
  vdpViews: IMetricType;
  demand: IMetricType;
  ddrCreated: IMetricType;
  ddrSales: IMetricType;
  deactivations: IMetricType;
  saleForecast: IMetricType;
  photoScore: IMetricType;
  commentScore: IMetricType;
  healthScore: IMetricType;
  averageMsrp: IMetricType;
  percentOfSalesTarget: IMetricType;
}

export interface IReputationMetricEnum {
  averageRating: IMetricType;
  totalPositiveReviews: IMetricType;
  totalNegativeReviews: IMetricType;
  totalReviews: IMetricType;
  totalDealerResponses: IMetricType;
}

export interface ISaleMetricEnum {
  none: IMetricType;
  totalSales: IMetricType;
  leadSales: IMetricType;
  leadContribution: IMetricType;
  averageTimeToSale: IMetricType;
  lostSales: IMetricType;
  nabrCloseRate: IMetricType;
  closeRate: IMetricType;
  totalLeads: IMetricType;
  dealerAverageLostSales: IMetricType;
}

export interface IReputationManagementMetricEnum {
  averageRating: IMetricType;
  totalPositiveReviews: IMetricType;
  totalNegativeReviews: IMetricType;
  totalReviews: IMetricType;
  totalDealerResponses: IMetricType;
}

export interface ISocialMediaMetricEnum {
  totalFansFollowers: IMetricType;
  totalPosts: IMetricType;
  totalReach: IMetricType;
  totalEngagement: IMetricType;
  totalAdvocacy: IMetricType;
}

export interface IWebsiteMetricEnum {
  none: IMetricType;
  totalVisits: IMetricType;
  uniqueVisits: IMetricType;
  websiteVisits: IMetricType;
  pageViews: IMetricType;
  servicePageViews: IMetricType;
  salesSessions: IMetricType;
  serviceSessions: IMetricType;
  avgTimeOnSite: IMetricType;
  newInventoryDetailViews: IMetricType;
  usedInventoryDetailViews: IMetricType;
  cpoInventoryDetailViews: IMetricType;
  formSubmissions: IMetricType;
  clickToCalls: IMetricType;
  phoneCalls: IMetricType;
  actions: IMetricType;
  actionRate: IMetricType;
  engagements: IMetricType;
  engagementRate: IMetricType;
  formConversionRate: IMetricType;
  phoneConversionRate: IMetricType;
  totalContacts: IMetricType;
  totalContactRate: IMetricType;
  bounceRate: IMetricType;
  bounces: IMetricType;
  vdpViews: IMetricType;
  newVdpViews: IMetricType;
  usedVdpViews: IMetricType;
  cpoVdpViews: IMetricType;
  otherVdpViews: IMetricType;
  totalVdpViews: IMetricType;
  newVlpViews: IMetricType;
  usedVlpViews: IMetricType;
  cpoVlpViews: IMetricType;
  otherVlpViews: IMetricType;
  mixedVlpViews: IMetricType;
  totalVlpViews: IMetricType;
  pageViewsPerVisit: IMetricType;
  tiguanPageViews: IMetricType;
  atlasPageViews: IMetricType;
  jettaPageViews: IMetricType;
  warrantyPageViews: IMetricType;
  offersIncentivesPageViews: IMetricType;
  cpoPageViews: IMetricType;
  totalLandingPageViews: IMetricType;
  accessoriesHomePageViews: IMetricType;
  competitiveAdvantagePageViews: IMetricType;
  dealerCouponPageViews: IMetricType;
  driverGearPageViews: IMetricType;
  maintenanceMenuPageViews: IMetricType;
  partsHomePageViews: IMetricType;
  scheduleServiceClicks: IMetricType;
  scheduleServicePageViews: IMetricType;
  serviceCcPageViews: IMetricType;
  serviceHomePageViews: IMetricType;
  serviceSpecialPageViews: IMetricType;
  serviceXpressPageViews: IMetricType;
  tireStorePageViews: IMetricType;
  totalAfterSalesPageViews: IMetricType;
  vwCarePageViews: IMetricType;
  dealerEnrollments: IMetricType;
  avgActions: IMetricType;
  avgEngagementRate: IMetricType;
  avgVisitors: IMetricType;
  clickToCallRate: IMetricType;
  qualifiedVisits: IMetricType;
  salesPageViews: IMetricType;
  hoursAndDirections: IMetricType;
  windowStickers: IMetricType;
  onlineServiceScheduler: IMetricType;
  tireFinderViews: IMetricType;
  bannerEngagements: IMetricType;
  salesSessionsConversionRate: IMetricType;
  serviceSessionsConversionRate: IMetricType;
}
export interface IHospitalityWebsiteMetricEnum {
  none: IMetricType;
  visits: IMetricType;
  looks: IMetricType;
  visitorsToLookRate: IMetricType;
  bookings: IMetricType;
  bookingInitiation: IMetricType;
  bookingInitiationRate: IMetricType;
  roomNights: IMetricType;
  revenue: IMetricType;
  pageViewPerVisit: IMetricType;
  websiteConversions: IMetricType;
  avgDailyRate: IMetricType;
  lengthOfStay: IMetricType;
  clickToCalls: IMetricType;
  visitorsToBookRate: IMetricType;
  uniqueVisitors: IMetricType;
  lookToBookRate: IMetricType;
}

export interface ITrendColor {
  id: number;
  name: string;
  hexString: string;
}

export interface ITrendColorEnum {
  green: ITrendColor;
  yellow: ITrendColor;
  red: ITrendColor;
  [x: string]: any;
  blue: ITrendColor;
  gray: ITrendColor;
}

export interface IVehicleStatus {
  vehicleStatusId: number;
  name: string;
}

export interface IVehicleStatusEnum {
  new: IVehicleStatus;
  used: IVehicleStatus;
}

export interface IEnums {
  accountTypes: IAccountTypeEnum;
  cacheKeys: ICacheKeys;
  certifiedChatProviders: ICertifiedChatProviderEnum;
  channels: IChannelEnum;
  channelTypes: IChannelTypeEnum;
  chartColors: IChartColorEnum;
  chatMetrics: IChatMetricEnum;
  chatProviders: IChatProviderEnum;
  closeRates: ICloseRateEnum;
  dataTableTypes: IDataTableType;
  dateModes: IDateModeEnum;
  dealerPerformanceGraphViews: IDealerPerformanceGraphViewEnum;
  demandPerformanceViews: IDemandPerformanceReportViewEnum;
  demandScenarios: IDemandScenarioEnum;
  demandScenarioMetrics: IDemandScenarioMetricEnum;
  demandScenarioGroups: IDemandScenarioGroupEnum;
  deviceTypes: IDeviceTypeEnum;
  deviceTypeGraphViews: IDeviceTypeGraphViewEnum;
  digitalAdvertisingChannels: IDigitalAdvertisingChannelEnum;
  digitalAdvertisingMetrics: IDigitalAdvertisingMetricEnum;
  digitalAdvertisingProviders: IDigitalAdvertisingProviderEnum;
  digitalRetailingMetrics: IDigitalRetailingMetricEnum;
  dispositionActivities: IDispositionActivityEnum;
  externalLinks: IExternalLinkEnum;
  guageColors: IGuageColorEnum;
  hierarchicalEntityTypes: IHierarchicalEntityTypeEnum;
  vehicleEntityTypes: IVehicleEntityTypeEnum;
  leadDesignations: ILeadDesignationEnum;
  leadTypes: ILeadTypeEnum;
  mediaTypes: IMediaTypeEnum;
  orgDateCodes: IOrgDateCodeEnum;
  // orgLookupTypes: IOrgLookupTypeEnum;
  pageTypes: IPageTypeEnum;
  vehicleLookupTypes: IVehicleLookupTypeEnum;
  printingOptions: IPrintingOptionEnum;
  referrerQualityGraphViews: IReferrerQualityGraphViewEnum;
  reportDateModes: IReportDateModeEnum;
  // roles: IRolesEnum;
  routeNames: IRouteNames;
  // routeHierarchy: IRouteHierarchy;
  routeCategories: IRouteCategories;
  salesUnits: ISalesUnitEnum;
  saleTypes: ISaleTypeEnum;
  sourceTypes: ISourceTypeEnum;
  trafficLevelTwoReportModes: ITrafficLevelTwoReportModeEnum;
  tradeInMetrics: ITradeInMetricEnum;
  vehicleMakes: IReportingVehicleMakeEnum;
  vehicleModels: IReportingVehicleModelEnum;
  vehicleClasses: IReportingVehicleClassEnum;
  facebookLeadMetrics: IFacebookLeadMetricEnum;
  leadMetrics: ILeadMetricEnum;
  inventoryMetrics: IInventoryMetricEnum;
  saleMetrics: ISaleMetricEnum;
  reputationManagementMetrics: IReputationManagementMetricEnum;
  reputationManagementProviders: IReputationManagementProviderEnum;
  socialMediaMetrics: ISocialMediaMetricEnum;
  socialMediaProviders: ISocialMediaProviderEnum;
  websiteMetrics: IWebsiteMetricEnum;
  hospitalityWebsiteMetrics: IHospitalityWebsiteMetricEnum;
  websiteProviders: IWebsiteProviderEnum;
  trendColors: ITrendColorEnum;
  vehicleStatus: IVehicleStatusEnum;
}

export const Enums: IEnums = {
  accountTypes: {
    orv: { displayName: 'ORV', nameForFilter: 'ORV Sub MCC' },
    snow: { displayName: 'Snow', nameForFilter: 'Snow Sub MCC' },
  },
  cacheKeys: {
    authData: 'authorizationData',
    rememberMe: 'rememberme',
    currentFilter: 'currentFilter',
    defaultFilter: 'defaultFilter',
    salesUnit: 'salesUnit',
    orgLookups: 'orgLookups',
    session: 'session'
  },
  certifiedChatProviders: {
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code:'ALLPROVIDERS' },
    activeEngage: { providerId: 1, name: 'ActiveEngage', displayName: 'ActiveEngage', code:'ACTIVENGAGE' },
    carChat24: { providerId: 2, name: 'CarChat24', displayName: 'CarChat24', code:'CARCHAT24' },
    carNow: { providerId: 3, name: 'CarNow', displayName: 'CarNow', code:'CARNOW' },
    contactAtOnce: { providerId: 4, name: 'ContactAtOnce', displayName: 'ContactAtOnce', code:'CONTACTATONCE' },
    gubagoo: { providerId: 5, name: 'Gubagoo', displayName: 'Gubagoo', code:'GUBAGOO' }
  },
  channels: {
    allChannels: { channelId: 0, channelTypeId: null, channelName: 'All Channels', channelColor: '', mediaTypeId: null },
    brand: { channelId: 1, channelTypeId: 1, channelName: 'Brand', channelColor: '#0892d1', mediaTypeId: 1 },
    paidSearch: { channelId: 2, channelTypeId: 2, channelName: 'Paid Search', channelColor: '#505050', mediaTypeId: 2 },
    organicSearch: { channelId: 3, channelTypeId: 1, channelName: 'Organic Search', channelColor: '#37658d', mediaTypeId: 1 },
    paidDisplay: { channelId: 4, channelTypeId: 2, channelName: 'Paid Display', channelColor: '#9a5c9c', mediaTypeId: 2 },
    paidVideo: { channelId: 5, channelTypeId: 2, channelName: 'Paid Video', channelColor: '#9abfd5', mediaTypeId: 2 },
    paidEmail: { channelId: 6, channelTypeId: 2, channelName: 'Paid Email', channelColor: '#cf8a52', mediaTypeId: 2 },
    organicEmail: { channelId: 7, channelTypeId: 1, channelName: 'Organic Email', channelColor: '#f6cba9', mediaTypeId: 1 },
    paidSocial: { channelId: 8, channelTypeId: 2, channelName: 'Paid Social', channelColor: '#3caeb3', mediaTypeId: 2 },
    organicSocial: { channelId: 9, channelTypeId: 1, channelName: 'Organic Social', channelColor: '#8cd9dc', mediaTypeId: 1 },
    thirdPartySites: { channelId: 10, channelTypeId: 1, channelName: 'Third Party Sites', channelColor: '#ef9a9a', mediaTypeId: 1 },
    typedBookmarked: { channelId: 11, channelTypeId: 1, channelName: 'Typed/Bookmarked', channelColor: '#bdc1c4', mediaTypeId: 1 },
    other: { channelId: 12, channelTypeId: 1, channelName: 'Other', channelColor: '#c4da98', mediaTypeId: 1 },
    groupSites: { channelId: 13, channelTypeId: 1, channelName: 'Group Sites', channelColor: '#c4da98', mediaTypeId: 1 },
    travelSites: { channelId: 14, channelTypeId: 1, channelName: 'Travel SItes', channelColor: '#c4da98', mediaTypeId: 1 },
    ldaSearch: { channelId: 18, channelTypeId: 2, channelName: 'LDA Search', channelColor: '#0892d1', mediaTypeId: 1 },
  },
  chartColors: {
    color1: '#000',
    color2: '#737373',
    color3: '#A6A6A6',
    color4: '#D9D9D9',
    color5: '#CCCCCC'
  },
  channelTypes: {
    organic: { channelTypeId: 1, name: 'Organic' },
    paid: { channelTypeId: 2, name: 'Paid' }
  },
  chatMetrics: {
    impressions: { metricId: 1, name: 'Impressions', nameKey: 'impressions' },
    chatsStarted: { metricId: 2, name: 'Chats Started', nameKey: 'starts' },
    chatStartRate: { metricId: 3, name: 'Chat Start Rate', nameKey: 'startRate' },
    chatsAbandoned: { metricId: 4, name: 'Chats Abandoned', nameKey: 'abandons' },
    chatsEngaged: { metricId: 5, name: 'Chats Engaged', nameKey: 'engagements' },
    chatEngagementRate: { metricId: 6, name: 'Chat Engagement Rate', nameKey: 'engagementRate' },
    avgMessagesPerChat: { metricId: 7, name: 'Average Messages Per Chat', nameKey: 'messagesPerChat' },
    chatLeads: { metricId: 8, name: 'Chat Leads', nameKey: 'leadVolume' },
    chatConversionRate: { metricId: 9, name: 'Chat Conversion Rate', nameKey: 'conversionRate' },
    dealerEnrollments: { metricId: 10, name: 'Participating Retailers', nameKey: 'dealerEnrollments' },
    chatAbandonRate: { metricId: 11, name: 'Chat Abandon Rate', nameKey: 'abandonRate' },
    visitsWithImpressions: { metricId: 12, name: 'Visits with Impressions', nameKey: 'visitsWithImpressions' },
    enrollments: { metricId: 13, name: 'Participating Retailers', nameKey: 'enrollments' }
  },
  chatProviders: {
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code: 'ALLPROVIDERS' },
    dealerInspire: { providerId: 2, name: 'Dealer Inspire', displayName: 'Dealer Inspire', code: 'DEALERINSPIRE' },
    activEngage: { providerId: 15, name: 'ActivEngage', displayName: 'ActivEngage', code: 'ACTIVENGAGE' },
    autoLeadStar: { providerId: 33, name: 'AutoLeadStar', displayName: 'AutoLeadStar', code: 'AUTOLEADSTAR' },
    carChat24: { providerId: 16, name: 'CarChat24', displayName: 'CarChat24', code: 'CARCHAT24' },
    carNow: { providerId: 12, name: 'CarNow', displayName: 'CarNow', code: 'CARNOW' },
    conversations: { providerId: 24, name: 'Conversations', displayName: 'Conversations', code: 'CONVERSATIONS' },
    dealereProcess: { providerId: 5, name: 'Dealer eProcess', displayName: 'Dealer eProcess', code: 'DEALEREPROCESS' },
    digitalAirStrike: { providerId: 13, name: 'Digital Air Strike', displayName: 'Digital Air Strike', code: 'DIGITALAIRSTRIKE' },
    edmunds: { providerId: 20, name: 'Edmunds', displayName: 'Edmunds', code: 'EDMUNDS' },
    gubagoo: { providerId: 17, name: 'Gubagoo', displayName: 'Gubagoo', code: 'GUBAGOO' },
    livePerson: { providerId: 18, name: 'LivePerson', displayName: 'LivePerson', code: 'LIVEPERSON' },
    podium: { providerId: 19, name: 'Podium', displayName: 'Podium', code: 'PODIUM' },
    fusionZone: { providerId: 10, name: 'fusionZone', displayName: 'fusionZone', code: 'FUSIONZONE' },
    kenect: { providerId: 38, name: 'Kenect', displayName: 'Kenect', code: 'KENECT' }
  },
  closeRates: {
    ninetyDay: { closeRateId: 1, name: 'Ninety Day Close Rate' },
    sixtyDay: { closeRateId: 2, name: 'Sixty Day Close Rate' },
    thirtyDay: { closeRateId: 3, name: 'Thirty Day Close Rate' }
  },
  dataTableTypes: {
    hierarchy: 'hierarchy',
    hierarchyV5: 'hierarchy-data-table',
    pagedSorted: 'paged-sorted',
    trend: 'trend',
    billboard: 'billboard'
  },
  dateModes: {
    lastThirtyDays: { dateModeId: 1, displayName: 'Last 30 Days' },
    lastMonth: { dateModeId: 2, displayName: 'Last Month' },
    currentMonth: { dateModeId: 3, displayName: 'This Month' },
    previousThreeMonths: { dateModeId: 4, displayName: 'Last 3 Months' },
    specificMonthOption: { dateModeId: 5, displayName: '' },
    custom: { dateModeId: 6, displayName: 'Custom' },
    previousTwelveMonths: { dateModeId: 7, displayName: 'Last 12 Months' },
    quarterly: { dateModeId: 8, displayName: 'Quarterly' },
    previousThirteenMonths: { dateModeId: 9, displayName: 'Last 13 Months' },
    yearToDate: { dateModeId: 10, displayName: 'Year to Date'}
  },
  dealerPerformanceGraphViews: {
    region: { viewId: 1, displayName: 'By Region' },
    dealer: { viewId: 2, displayName: 'By Dealer' },
    total: { viewId: 3, displayName: 'Total' }
  },
  demandPerformanceViews: {
    dealerPerformance: { viewId: 1, displayName: 'Dealer Performance' },
    sourcePerformance: { viewId: 2, displayName: 'Source Performance', altDisplayName: 'Lead Source Performance' },
    newInventoryByDealer: { viewId: 3, displayName: 'New Inventory By Dealer' },
    newInventoryByBrand: { viewId: 4, displayName: 'New Inventory By Brand' },
    usedInventoryByDealer: { viewId: 5, displayName: 'Used Inventory By Dealer' }
  },
  demandScenarios: {
    total: { id: 0, name: 'Total' },
    leads: { id: 1, name: 'Leads' },
    traffic: { id: 2, name: 'Traffic' },
    newInventory: { id: 3, name: 'New Inventory' },
    usedInventory: { id: 4, name: 'Used Inventory' }
  },
  demandScenarioMetrics: {
    none: { id: 0, name: 'None', scenarioId: 0 },
    totalLeads: { id: 1, name: 'Total Leads', scenarioId: 1 },
    totalLeadCloseRate: { id: 2, name: 'Total Lead Close Rate', scenarioId: 1 },
    newLeads: { id: 3, name: 'New Leads', scenarioId: 1 },
    newLeadCloseRate: { id: 4, name: 'New Lead Close Rate', scenarioId: 1 },
    usedLeads: { id: 5, name: 'Used Leads', scenarioId: 1 },
    usedLeadCloseRate: { id: 6, name: 'Used Lead Close Rate', scenarioId: 1 },
    totalVisits: { id: 7, name: 'Total Visits', scenarioId: 2 },
    uniqueVisitors: { id: 8, name: 'Unique Visitors', scenarioId: 2 },
    engagedVisits: { id: 9, name: 'Engaged Visits', scenarioId: 2 },
    engagementRate: { id: 10, name: 'Engagement Rate', scenarioId: 2 },
    salesPageViews: { id: 11, name: 'Sales Page Views', scenarioId: 2 },
    salesPageViewsPerVisit: { id: 12, name: 'Sales Page Views Per Visit', scenarioId: 2 },
    actions: { id: 13, name: 'Actions', scenarioId: 2 },
    actionRate: { id: 14, name: 'Action Rate', scenarioId: 2 },
    newTotalVins: { id: 15, name: 'Total VINs', scenarioId: 3 },
    newVdpsPerVin: { id: 16, name: 'VDPs/VIN', scenarioId: 3 },
    newDaysOnLot: { id: 17, name: 'Days on Lot', scenarioId: 3 },
    newHealthScore: { id: 18, name: 'Health Score', scenarioId: 3 },
    usedTotalVins: { id: 19, name: 'Total VINs', scenarioId: 4 },
    usedVdpsPerVin: { id: 20, name: 'VDPs/VIN', scenarioId: 4 },
    usedDaysOnLot: { id: 21, name: 'Days on Lot', scenarioId: 4 },
    usedHealthScore: { id: 22, name: 'Health Score', scenarioId: 4 }
  },
  demandScenarioGroups: {
    leadTotal: { id: 1, name: 'Total', scenarioId: 1, nameKey: null },
    leadSourceCategory: { id: 2, name: 'Source Category', scenarioId: 1, nameKey: 'sourcecategory' },
    leadSourceType: { id: 3, name: 'Source Type', scenarioId: 1, nameKey: 'sourcetype' },
    leadRegion: { id: 4, name: 'Region', scenarioId: 1, nameKey: 'region' },
    leadDealer: { id: 5, name: 'Dealer', scenarioId: 1, nameKey: 'dealer' },
    trafficTotal: { id: 6, name: 'Total', scenarioId: 2, nameKey: null },
    trafficMediaType: { id: 7, name: 'Media Type', scenarioId: 2, nameKey: 'mediatype' },
    trafficChannel: { id: 8, name: 'Channel', scenarioId: 2, nameKey: 'channel' },
    trafficRegion: { id: 9, name: 'Region', scenarioId: 2, nameKey: 'region' },
    trafficDealer: { id: 10, name: 'Dealer', scenarioId: 2, nameKey: 'dealer' },
    newInventoryTotal: { id: 11, name: 'Total', scenarioId: 3, nameKey: null },
    newInventoryMake: { id: 12, name: 'Make', scenarioId: 3, nameKey: 'make' },
    newInventoryRegion: { id: 13, name: 'Region', scenarioId: 3, nameKey: 'region' },
    newInventoryDealer: { id: 14, name: 'Dealer', scenarioId: 3, nameKey: 'dealer' },
    usedInventoryTotal: { id: 15, name: 'Total', scenarioId: 4, nameKey: null },
    usedInventoryRegion: { id: 16, name: 'Region', scenarioId: 4, nameKey: 'region' },
    usedInventoryDealer: { id: 17, name: 'Dealer', scenarioId: 4, nameKey: 'dealer' }
  },
  deviceTypes: {
    desktop: { deviceTypeId: 1, name: 'Desktop' },
    tablet: { deviceTypeId: 2, name: 'Tablet' },
    phone: { deviceTypeId: 3, name: 'Phone' }
  },
  deviceTypeGraphViews: {
    allDevices: { viewId: 0, displayName: 'All Devices' },
    desktop: { viewId: 1, displayName: 'Desktop' },
    phone: { viewId: 2, displayName: 'Phone' },
    tablet: { viewId: 3, displayName: 'Tablet' },
    total: { viewId: 4, displayName: 'Total' }
  },
  digitalAdvertisingChannels: {
    allChannels: { channelId: 0, channelName: 'All Channels', channelColor: '', channelTypeId: 2, mediaTypeId: 0 },
    search: { channelId: 1, channelName: 'Search', channelColor: '', channelTypeId: 2, mediaTypeId: 1 },
    display: { channelId: 2, channelName: 'Display', channelColor: '', channelTypeId: 2, mediaTypeId: 2 },
    video: { channelId: 3, channelName: 'Video', channelColor: '', channelTypeId: 2, mediaTypeId: 3 },
    social: { channelId: 4, channelName: 'Social', channelColor: '', channelTypeId: 2, mediaTypeId: 4 },
    email: { channelId: 5, channelName: 'Email', channelColor: '', channelTypeId: 2, mediaTypeId: 5 },
  },
  digitalAdvertisingMetrics: {
    none: { metricId: 0, name: 'None' },
    impressions: { metricId: 1, name: 'Impressions', nameKey: 'impressions' },
    clicks: { metricId: 2, name: 'Clicks', nameKey: 'clicks' },
    totalVisits: { metricId: 3, name: 'Total Visits', nameKey: 'totalVisits' },
    uniqueVisitors: { metricId: 4, name: 'Unique Visitors', nameKey: 'uniqueVisitors' },
    formSubmissions: { metricId: 5, name: 'Form Submissions', nameKey: 'formSubmissions' },
    phoneCalls: { metricId: 6, name: 'Phone Calls', nameKey: 'phoneCalls' },
    bounceRate: { metricId: 7, name: 'Bounce Rate', nameKey: 'bounceRate' },
    actionRate: { metricId: 8, name: 'Action Rate', nameKey: 'actionRate' },
    engagementRate: { metricId: 9, name: 'Engagement Rate', nameKey: 'engagementRate' },
    conversionRate: { metricId: 10, name: 'Conversion Rate', nameKey: 'conversionRate' },
    actualCostPerAcquisition: { metricId: 11, name: 'Cost Per Acquisition', nameKey: 'actualCostPerAcquisition' },
    budgetCostPerAcquisition: { metricId: 12, name: 'Cost Per Acquisition', nameKey: 'budgetCostPerAcquisition' },
    clickThroughRate: { metricId: 13, name: 'Click Through Rate', nameKey: 'clickThroughRate' },
    actualCostPerClick: { metricId: 14, name: 'Cost Per Click', nameKey: 'actualCostPerClick' },
    budgetCostPerClick: { metricId: 15, name: 'Cost Per Click', nameKey: 'budgetCostPerClick' },
    actualCostPerImpression: { metricId: 16, name: 'Cost Per Impression', nameKey: 'actualCostPerImpression' },
    budgetCostPerImpression: { metricId: 17, name: 'Cost Per Impression', nameKey: 'budgetCostPerImpression' },
    chats: { metricId: 18, name: 'Chats', nameKey: 'chats' },
    chatRate: { metricId: 19, name: 'Chat Rate', nameKey: 'chatRate' },
    calculatedBudgetCostPerAcquisition: { metricId: 20, name: 'Cost Per Acquisition', nameKey: 'calculatedBudgetCostPerAcquisition' },
    calculatedBudgetCostPerClick: { metricId: 21, name: 'Cost Per Click', nameKey: 'calculatedBudgetCostPerClick' },
    calculatedBudgetCostPerImpression: { metricId: 22, name: 'Cost Per Impression', nameKey: 'calculatedBudgetCostPerImpression' },
    socialReach: { metricId: 23, name: 'Social Reach', nameKey: 'socialReach' },
    socialFrequency: { metricId: 24, name: 'Social Frequency', nameKey: 'socialFrequency' },
    completedVideoViews: { metricId: 25, name: 'Completed Video Views', nameKey: 'completedVideoViews' },
    videoViews: { metricId: 26, name: 'Video Views', nameKey: 'videoViews' },
    videoViewRate: { metricId: 27, name: 'Video Views Rate', nameKey: 'videoViewRate' },
    serviceSchedulers: { metricId: 28, name: 'Service Schedulers', nameKey: 'serviceSchedulers' },
    partOrders: { metricId: 29, name: 'Part Orders', nameKey: 'partOrders' },
    qualifiedVisits: { metricId: 30, name: 'Qualified Visits', nameKey: 'qualifiedVisits' },
    vlpViews: { metricId: 31, name: 'VLP Views', nameKey: 'vlps' },
    vdpViews: { metricId: 32, name: 'VDP Views', nameKey: 'vdps' },
    bounces: { metricId: 33, name: 'Bounces', nameKey: 'bounces' },
    midpointViews: { metricId: 34, name: 'Midpoint Views', nameKey: 'midpointViews' },
    completedVideoViewRate: { metricId: 37, name: 'Video Completion Rate', nameKey: 'completedVideoViewRate' },
    actualCostPerAction: { metricId: 38, name: 'Cost Per Action', nameKey: 'actualCostPerAction' },
    taggingFormSubmissions: { metricId: 39, name: 'Form Submissions', nameKey: 'taggingFormSubmissions' },
    providerFormSubmissions: { metricId: 40, name: 'Form Submissions', nameKey: 'providerFormSubmissions' },
    taggingTotalVisits: { metricId: 41, name: 'Total Visits', nameKey: 'taggingTotalVisits' },
    taggingUniqueVisitors: { metricId: 42, name: 'Unique Visitors', nameKey: 'taggingUniqueVisits' },
    providerTotalVisits: { metricId: 43, name: 'Total Visits', nameKey: 'providerTotalVisits' },
    providerUniqueVisitors: { metricId: 44, name: 'Unique Visitors', nameKey: 'providerUniqueVisits' },
    taggingBounceRate: { metricId: 45, name: 'Bounce Rate', nameKey: 'taggingBounceRate' },
    providerBounceRate: { metricId: 46, name: 'Bounce Rate', nameKey: 'providerBounceRate' },
    completions: { metricId: 47, name: 'Completions', nameKey: 'completions' },
    completionRate: { metricId: 48, name: 'Completion Rate', nameKey: 'completionRate' },
    enrollment: { metricId: 49, name: 'Enrollment', nameKey: 'dealerEnrollments' },
    avgImpressions: { metricId: 50, name: 'Avg. Impressions', nameKey: 'averageImpressions' },
    avgClickThroughRate: { metricId: 51, name: 'Avg. Click Through Rate', nameKey: 'averageClickThroughRate' },
    avgEngagementRate: { metricId: 52, name: 'Avg. Engagement Rate', nameKey: 'averageEngagementRate' },
    avgActionRate: { metricId: 53, name: 'Avg. Action Rate', nameKey: 'averageActionRate' },
    percentageOfTotalEnrollment: { metricId: 54, name: 'Percentage of Total Enrollment', nameKey: 'percentageOfTotalEnrollment' },
  },
  digitalAdvertisingProviders: { // double check provider id's!!!!
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code: 'ALLPROVIDERS' },
    dealerFire: { providerId: 1, name: 'DealerFire', displayName: 'DealerFire', code: 'DEALERFIRE' },
    dealerInspire: { providerId: 2, name: 'Dealer Inspire', displayName: 'Dealer Inspire', code: 'DEALERINSPIRE' },
    dealerOn: { providerId: 3, name: 'DealerOn', displayName: 'DealerOn', code: 'DEALERON' },
    dealerDotCom: { providerId: 4, name: 'Dealer.com', displayName: 'Dealer.com', code: 'DEALERCOM' },
    // dealer eProcess 5
    // DOM 360 6
    dominion: { providerId: 7, name: 'Search Optics', displayName: 'Search Optics', code: 'SEARCHOPTICS' },
    // AutoLoop 8
    stream: { providerId: 9, name: 'Stream', displayName: 'Stream', code: 'STREAM' },
    // FusionZone 10
    // Dealer Venom 11
    // CarNow 12
    // Digital Air Strike 13
    // TradePending 14
    // ActivEngage 15
    // CarChat24 16
    // Gubagoo 17
    // LivePerson 18
    // Podium 19
    // Edmunds 20
    ebizAutos: { providerId: 21, name: 'ebizAutos', displayName: 'ebizAutos', code: 'EBIZAUTOS' },
    // Strathcom 22
    // SM360 23
    // Conversations 24
    cdk: { providerId: 25, name: 'CDK Global', displayName: 'CDK Global', code: 'CDK' },
    // eDealer 26
    // Fox Dealer 27
    pixelMotion: { providerId: 28, name: 'Pixel Motion', displayName: 'Pixel Motion', code: 'PIXELMOTION' },
    allAutoNetwork: { providerId: 29, name: 'All Auto Network', displayName: 'All Auto Network', code: 'ALLAUTONETWORK' },
    constellation: { providerId: 30, name: 'Constellation', displayName: 'Constellation', code: 'CONSTELLATION' },
    pureCars: { providerId: 31, name: 'PureCars', displayName: 'Pure Cars', code: 'PURECARS' },
    teamVelocity: { providerId: 32, name: 'Team Velocity', displayName: 'Team Velocity', code: 'TEAMVELOCITY' },
    nerdAuto: { providerId: 33, name: 'Nerd Auto', displayName: 'Nerd Auto', code: 'NERDAUTO' },
    magnetis: { providerId: 34, name: 'Magnetis', displayName: 'Magnetis', code: 'MAGNETIS' },
    shiftDigital: { providerId: 36, name: 'Shift Digital', displayName: 'Shift Digital', code: 'sd' },
    dealerSpike: { providerId: 37, name: 'Dealer Spike', displayName: 'Dealer Spike', code: 'DEALERSPIKE' },
    dealerTeamwork: { providerId: 40, name: 'DealerTeamwork', displayName: 'Dealer Teamwork', code: 'DEALERTEAMWORK' },
    forceMarketing: { providerId: 41, name: 'ForceMarketing', displayName: 'Force Marketing', code: 'FORCEMARKETING' },
  },
  digitalRetailingMetrics: {
    none: { metricId: 0, name: 'None' },
    sessionStarts: { metricId: 1, name: 'Sessions Started', nameKey: 'sessionStarts' },
    sessionStartRate: { metricId: 2, name: 'Session Start Rate', nameKey: 'sessionStartRate' },
    uniqueShoppers: { metricId: 3, name: 'Unique Shoppers', nameKey: 'uniqueShoppers' },
    visits: { metricId: 4, name: 'Website Visits', nameKey: 'visits' },
    visitRate: { metricId: 5, name: 'Website Visit Rate', nameKey: 'visitRate' },
    visitors: { metricId: 6, name: 'Website Visitors', nameKey: 'visitors' },
    visitorRate: { metricId: 7, name: 'Website Visitor Rate', nameKey: 'visitorRate' },
    leadCaptures: { metricId: 8, name: 'Lead Captures', nameKey: 'leadCaptures' },
    leadCaptureRate: { metricId: 9, name: 'Lead Capture Rate', nameKey: 'leadCaptureRate' },
    tradeIns: { metricId: 10, name: 'Trade-Ins', nameKey: 'tradeIns' },
    tradeInRate: { metricId: 11, name: 'Trade-In Rate', nameKey: 'tradeInRate' },
    financeFinishes: { metricId: 12, name: 'Finance Finishes', nameKey: 'financeFinishes' },
    financeFinishRate: { metricId: 13, name: 'Finance Finish Rate', nameKey: 'financeFinishRate' },
    preQualifieds: { metricId: 14, name: 'Pre-Qualified', nameKey: 'preQualified' },
    preQualifiedRate: { metricId: 15, name: 'Pre-Qualified Rate', nameKey: 'preQualifiedRate' },
    creditApps: { metricId: 16, name: 'Credit Apps', nameKey: 'creditApps' },
    creditAppRate: { metricId: 17, name: 'Credit App Rate', nameKey: 'creditAppRate' },
    allCreditApps: { metricId: 18, name: 'All Credit Apps', nameKey: 'allCreditApps' },
    allCreditAppRate: { metricId: 19, name: 'All Credit Apps Rate', nameKey: 'allCreditAppRate' },
    appointments: { metricId: 20, name: 'Appointments', nameKey: 'appointments' },
    appointmentRate: { metricId: 21, name: 'Appointment Rate', nameKey: 'appointmentRate' },
    paymentCalculators: { metricId: 22, name: 'Payment Calculator Completes', nameKey: 'paymentCalcs'},
    paymentCalculatorRate: { metricId: 23, name: 'Payment Calculator Completed Rate', nameKey: 'paymentCalcRate' },
    paymentCalcStarts: { metricId: 24, name: 'Payment Calculator Starts', nameKey: 'paymentCalcStarts'},
    paymentCalcStartRate: { metricId: 25, name: 'Payment Calculator Start Rate', nameKey: 'paymentCalcStartRate' },
    formSubmissions: { metricId: 26, name: 'Form Submissions', nameKey: 'formSubmissions' },
    formSubmissionRate: { metricId: 27, name: 'Form Submission Rate', nameKey: 'formSubmissionRate' },
    eContractingRate: { metricId: 28, name: 'E-Contracting Rate', nameKey: 'eContractingRate' },
    eContracting: { metricId: 29, name: 'E-Contracting', nameKey: 'eContracting' },
    depositRate: { metricId: 30, name: 'Deposit Rate', nameKey: 'depositRate' },
    deposits: { metricId: 31, name: 'Deposits', nameKey: 'deposits' },
    incentives: { metricId: 32, name: 'Incentives', nameKey: 'incentives' },
    incentiveRate: { metricId: 33, name: 'Incentive Rate', nameKey: 'incentiveRate' },
    accessories: { metricId: 34, name: 'Accessories', nameKey: 'accessories' },
    accessoriesRate: { metricId: 35, name: 'Accessories Rate', nameKey: 'accessoriesRate' },
  },
  tradeInMetrics: {
    none: { metricId: 0, name: 'None' },
    impressions: { metricId: 1, name: 'Impressions', nameKey: 'impressions' },
    visitsWithImpressions: { metricId: 2, name: 'Visits With Impressions', nameKey: 'visitsWithImpressions'},
    starts: { metricId: 3, name: 'Trade-In Starts', nameKey: 'starts' },
    startRate: { metricId: 4, name: 'Trade-In Start Rate', nameKey: 'startRate' },
    vehicleIndicateds: { metricId: 5, name: 'Vehicle Selections', nameKey: 'vehicleIndicateds' },
    vehicleIndicatedRate: { metricId: 6, name: 'Vehicle Selection Rate', nameKey: 'vehicleIndicatedRate' },
    formInitiations: { metricId: 7, name: 'Form Initiations', nameKey: 'formInitiations' },
    formInitiationRate: { metricId: 8, name: 'Form Initiation Rate', nameKey: 'formInitiationRate' },
    formSubmissions: { metricId: 9, name: 'Form Submission', nameKey: 'formSubmissions' },
    formSubmissionRate: { metricId: 10, name: 'Form Submission Rate', nameKey: 'formSubmissionRate' },
    appointments: { metricId: 11, name: 'Appointments', nameKey: 'appointments' },
    appointmentRate: { metricId: 12, name: 'Appointment Rate', nameKey: 'appointmentRate' },
    completions: { metricId: 13, name: 'Completions', nameKey: 'completions' },
    completionRate: { metricId: 14, name: 'Completion Rate', nameKey: 'completionRate' },
    leadVolumn: { metricId: 15, name: 'Trade-In Leads', nameKey: 'leadVolume' },
    conversionRate: { metricId: 16, name: 'Trade-In Lead Conversion', nameKey: 'conversionRate' },
    dealerEnrollments: { metricId: 17, name: 'Participating Retailers', nameKey: 'dealerEnrollments' },
    enrollments: { metricId: 18, name: 'Participating Retailers', nameKey: 'enrollments' }
  },
  dispositionActivities: {
    appointment: { dispositionActivityId: 7, name: 'Appointment Set' }
  },
  externalLinks: {
    mysteryShop: { name: 'Mystery Shop', url: 'https://www.google.com' }
  },
  guageColors: {
    green: '#1fc23e',
    yellow: '#ffae13',
    red: '#c21d30'
  },
  facebookLeadMetrics: {
    none: { metricId: 0, name: 'None' },
    leadCount: { metricId: 1, name: 'Lead Count', nameKey: 'leadCount' },
    saleCount: { metricId: 2, name: 'Sale Count', nameKey: 'saleCount' },
    conversionRate: { metricId: 3, name: 'Conversion Rate', nameKey: 'conversionRate'}
  },
  hierarchicalEntityTypes: {
    dealer: { type: 'dealer', displayName: 'Dealer' },
    lma: { type: 'lma', displayName: 'LMA' },
    orgcode1: { type: 'orgcode1', displayName: 'Market' },
    orgcode2: { type: 'orgcode2', displayName: 'Area' },
    orgcode3: { type: 'orgcode3', displayName: 'Region' },
    orgcode4: { type: 'orgcode4', displayName: 'Org 4' },
    orgcode5: { type: 'orgcode5', displayName: 'Org 5' },
    national: { type: 'national', displayName: 'National' },
  },
  inventoryMetrics: {
    inventory: { metricId: 0, name: 'Inventory Level', nameKey: 'inventory' },
    daysSupply: { metricId: 1, name: 'Days Supply', nameKey: 'daysSupply' },
    vdpViews: { metricId: 2, name: 'Vdp Views', nameKey: 'vdpViews' },
    demand: { metricId: 3, name: 'VDP/VIN', nameKey: 'demand' },
    ddrCreated: { metricId: 4, name: 'DDR Created', nameKey: 'ddrCreated' },
    ddrSales: { metricId: 5, name: 'DDR Sales', nameKey: 'ddrSales' },
    deactivations: { metricId: 6, name: 'Inventory Sales', nameKey: 'deactivations' },
    saleForecast: { metricId: 7, name: 'Forecast', nameKey: 'saleForecast' },
    photoScore: { metricId: 8, name: 'Photo Score', nameKey: 'photoScore' },
    commentScore: { metricId: 9, name: 'Comment Score', nameKey: 'commentScore' },
    healthScore: { metricId: 10, name: 'Health Score', nameKey: 'healthScore' },
    averageMsrp: { metricId: 11, name: 'Close Rate', nameKey: 'averageMsrp' },
    percentOfSalesTarget: { metricId: 12, name: '% Sales Target', nameKey: 'percentOfSalesTarget' }
  },
  leadMetrics: {
    none: { metricId: 0, name: 'None', nameKey: 'none' },
    leadVolume: { metricId: 1, name: 'Lead Volume', nameKey: 'leadVolume' },
    responseRate: { metricId: 2, name: 'Response Rate', nameKey: 'responseRate' },
    responseRate60: { metricId: 3, name: 'Response Rate < 1h', nameKey: 'responseRate60' },
    responseRate40: { metricId: 4, name: 'Response Rate < 40m', nameKey: 'responseRate40' },
    responseRate30: { metricId: 5, name: 'Response Rate < 30m', nameKey: 'responseRate30' },
    responseRate15: { metricId: 6, name: 'Response Rate < 15m', nameKey: 'responseRate15' },
    leadSales: { metricId: 7, name: 'Lead Sales', nameKey: 'leadSales' },
    closeRate120: { metricId: 8, name: '120 Day Close Rate', nameKey: 'closeRate120' },
    closeRate90: { metricId: 9, name: '90 Day Close Rate', nameKey: 'closeRate90' },
    closeRate60: { metricId: 10, name: '60 Day Close Rate', nameKey: 'closeRate60' },
    closeRate30: { metricId: 11, name: '30 Day Close Rate', nameKey: 'closeRate30' },
    closeRate: { metricId: 12, name: 'Close Rate', nameKey: 'closeRate' },
    gainedSales: { metricId: 13, name: 'Gained Sales', nameKey: 'gainedSales' },
    lostSales: { metricId: 14, name: 'Lost Sales', nameKey: 'lostSales' },
    percentOfLeads: { metricId: 15, name: '% of Leads', nameKey: 'percentOfLeads' },
    percentOfSales: { metricId: 16, name: '% of Sales', nameKey: 'percentOfSales' },
    conversionRate: { metricId: 17, name: 'Conversion Rate', nameKey: 'conversionRate' },
    uniqueLeads: { metricId: 18, name: 'Unique Lead Volume', nameKey: 'uniqueLeads' },
    attributedCloseRate: { metricId: 19, name: 'Attributed Close Rate', nameKey: 'attributedCloseRate' },
    averageResponseTime48Hour: { metricId: 20, name: 'Avg. Response Time (48 Hr)', nameKey: 'averageResponseTime48Hour' },
    mbUsaDotComLeads: { metricId: 21, name: 'MBUSA.com Leads', nameKey: 'mbUsaDotComLeads' },
    otherBrandLeads: { metricId: 22, name: 'Other Brand Leads', nameKey: 'otherBrandSiteLeads' },
    dealerWebsiteLeads: { metricId: 23, name: 'Dealer Website Leads', nameKey: 'dealerWebsiteLeads' },
    eventLeads: { metricId: 24, name: 'Event Leads', nameKey: 'eventLeads' },
    thirdPartyLeads: { metricId: 25, name: 'Third Party Leads', nameKey: 'thirdPartyLeads' },
    appointmentRate: { metricId: 26, name: 'Appointment Rate', nameKey: 'appointmentRate' },
    archivedLeadVolume: { metricId: 27, name: 'Archived Lead Volume', nameKey: 'archivedLeadVolume' },
    archivedLeadSales: { metricId: 28, name: 'Archived Lead Sales', nameKey: 'archivedLeadSales' },
    archivedConversionRate: { metricId: 29, name: 'Archived Conversion Rate', nameKey: 'archivedConversionRate' },
    averageDailyVolume: { metricId: 30, name: 'Avg. Daily Volume', nameKey: 'averageDailyVolume' },
    brandSiteLeads: { metricId: 31, name: 'Brand Leads', nameKey: 'brandSiteLeads' },
    dealerGeneratedLeads: { metricId: 32, name: 'Dealer Generated Leads', nameKey: 'dealerGeneratedLeads' },
    averageResponseTime: { metricId: 33, name: 'Overall Avg. Response Time', nameKey: 'averageResponseTime' },
    digitalRetailLeads: { metricId: 34, name: 'Digital Retail Leads', nameKey: 'digitalRetailLeads' },
    nabrLeadVolume: { metricId: 35, name: 'NABR Leads', nameKey: 'nabrLeadVolume' },
    brandNabrLeads: { metricId: 36, name: 'Brand NABR Leads', nameKey: 'brandNabrLeads' },

    leadVolumePma: { metricId: 37, name: 'In PMA Lead Volume', nameKey: 'leadVolumePma' },
    leadVolumeNonPma: { metricId: 38, name: 'Outside PMA Lead Volume', nameKey: 'leadVolumeNonPma' },
    leadSalesPma: { metricId: 39, name: 'In PMA Lead Sales', nameKey: 'leadSalesPma' },
    leadSalesNonPma: { metricId: 40, name: 'Outside PMA Lead Sales', nameKey: 'leadSalesNonPma' },
    closeRatePma: { metricId: 41, name: 'In PMA Close Rate', nameKey: 'closeRatePma' },
    closeRateNonPma: { metricId: 42, name: 'Outside PMA Close Rate', nameKey: 'closeRateNonPma' },
    conversionRatePma: { metricId: 43, name: 'In PMA Conversion Rate', nameKey: 'conversionRatePma' },
    conversionRateNonPma: { metricId: 44, name: 'Outside PMA Conversion Rate', nameKey: 'conversionRateNonPma' },
    responseRatePma: { metricId: 45, name: 'In PMA Response Rate', nameKey: 'responseRatePma' },
    responseRateNonPma: { metricId: 46, name: 'Outside PMA Response Rate', nameKey: 'responseRateNonPma' },
    responseRate60Pma: { metricId: 47, name: 'In PMA Response Rate < 1 Hr', nameKey: 'responseRate60Pma' },
    responseRate60NonPma: { metricId: 48, name: 'Outside PMA Response Rate < 1 Hr', nameKey: 'responseRate60NonPma' },
    appointmentRatePma: { metricId: 49, name: 'In PMA Appointment Rate', nameKey: 'appointmentRatePma' },
    appointmentRateNonPma: { metricId: 50, name: 'Outside PMA Appointment Rate', nameKey: 'appointmentRateNonPma' },
    marketingLeads: { metricId: 51, name: 'Marketing Leads', nameKey: 'marketingLeads' },
    responseRate120: { metricId: 52, name: 'Response Rate < 2h', nameKey: 'responseRate120' },
    responseRate180: { metricId: 53, name: 'Response Rate < 3h', nameKey: 'responseRate180' },
    closeRate150: { metricId: 54, name: '150 Day Close Rate', nameKey: 'closeRate150' },
    closeRate180: { metricId: 55, name: '180 Day Close Rate', nameKey: 'closeRate180' },
    bestMatchSales: { metricId: 56, name: 'Lead Sales', nameKey: 'bestMatchSales' },
    bestMatchCloseRate: { metricId: 57, name: 'Close Rate', nameKey: 'bestMatchCloseRate' },
    bestMatchCloseRate30: { metricId: 58, name: '30-Day Close Rate', nameKey: 'bestMatchCloseRate30' },
    bestMatchCloseRate60: { metricId: 59, name: '60-Day Lead Sales', nameKey: 'bestMatchCloseRate60' },
    bestMatchCloseRate90: { metricId: 60, name: '90-Day Lead Sales', nameKey: 'bestMatchCloseRate90' },
    bestMatchCloseRate120: { metricId: 61, name: '120-Day Lead Sales', nameKey: 'bestMatchCloseRate120' },
    bestMatchCloseRate150: { metricId: 62, name: '150-Day Lead Sales', nameKey: 'bestMatchCloseRate150' },
    bestMatchCloseRate180: { metricId: 63, name: '180-Day Lead Sales', nameKey: 'bestMatchCloseRate180' },
    lostDealerSales: { metricId: 64, name: 'Lost Sales', nameKey: 'lostDealerSales' },
    conversionRate30: { metricId: 65, name: '30-Day Conversion Rate', nameKey: 'conversionRate30' },
    conversionRate60: { metricId: 66, name: '60-Day Conversion Rate', nameKey: 'conversionRate60' },
    conversionRate90: { metricId: 67, name: '90-Day Conversion Rate', nameKey: 'conversionRate90' },
    conversionRate120: { metricId: 68, name: '120-Day Conversion Rate', nameKey: 'conversionRate120' },
    responseRate45: { metricId: 69, name: 'Response Rate < 45m', nameKey: 'responseRate45' },
    averageCommunications: { metricId: 70, name: 'Avg. Communications', nameKey: 'averageCommunications' },
  },
  leadDesignations: {
    sentToDealer: { leadDesignationId: 7, name: 'Sent to Dealer' }
  },
  leadTypes: {
    new: { leadTypeId: 1, name: 'New Sales' },
    finance: { leadTypeId: 4, name: 'Finance' },
    afterSales: { leadTypeId: 7, name: 'After Sales' },
    used: { leadTypeId: 2, name: 'Used Sales' },
    cpo: { leadTypeId: 8, name: 'CPO' },
  },
  reputationManagementMetrics: {
    averageRating: { metricId: 1, name: 'Average Rating', nameKey: 'averageRating' },
    totalPositiveReviews: { metricId: 2, name: 'Total Positive Reviews', nameKey: 'totalPositive' },
    totalNegativeReviews: { metricId: 3, name: 'Total Negative Reviews', nameKey: 'totalNegative' },
    totalReviews: { metricId: 4, name: 'Total Reviews', nameKey: 'totalReviews' },
    totalDealerResponses: { metricId: 5, name: 'Total Dealer Responses', nameKey: 'totalDealerResponses' },
  },
  reputationManagementProviders: {
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code: 'ALLPROVIDERS' },
    friendemic: {providerId: 16, name: 'Friendemic', displayName: 'Friendemic', code: 'FRIENDEMIC' },
    digitalAirStrike: {providerId: 17, name: 'Digital Air Strike', displayName: 'Digital Air Strike', code: 'DIGITALAIRSTRIKE' },
    podium: {providerId: 18, name: 'Podium', displayName: 'Podium', code: 'PODIUM' },
  },
  saleMetrics: {
    none: { metricId: 0, name: 'None', nameKey: 'none' },
    totalSales: { metricId: 1, name: 'Total Sales', nameKey: 'totalSales' },
    leadSales: { metricId: 2, name: 'Sales From Leads', nameKey: 'leadSales' },
    leadContribution: { metricId: 3, name: 'Lead Contr. Rate', nameKey: 'leadContribution' },
    averageTimeToSale: { metricId: 4, name: 'Avg. Time to Sale', nameKey: 'averageTimeToSale' },
    lostSales: { metricId: 5, name: 'Avg. Dealer Lost Sales', nameKey: 'lostSales' },
    nabrCloseRate: { metricId: 6, name: 'NABR Close Rate', nameKey: 'nabrCloseRate' },
    closeRate: { metricId: 7, name: 'Close Rate', nameKey: 'closeRate' },
    totalLeads: { metricId: 8, name: 'Total Leads', nameKey: 'totalLeads' },
    dealerAverageLostSales: { metricId: 9, name: 'Dealer Avg. Lost Sales', nameKey: 'lostSalesDealerAverage'}
  },
  socialMediaMetrics: {
    totalFansFollowers: { metricId: 1, name: 'Total Fans/Followers', nameKey: 'totalFansFollowers' },
    totalPosts: { metricId: 2, name: 'Total Posts', nameKey: 'totalPosts' },
    totalReach: { metricId: 3, name: 'Total Reach', nameKey: 'totalReach' },
    totalEngagement: { metricId: 4, name: 'Total Engagement', nameKey: 'totalEngagement' },
    totalAdvocacy: { metricId: 5, name: 'Total Advocacy', nameKey: 'totalAdvocacy' },
  },
  socialMediaProviders: {
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code: 'ALLPROVIDERS' },
    friendemic: {providerId: 16, name: 'Friendemic', displayName: 'Friendemic', code: 'FRIENDEMIC' },
    digitalAirStrike: {providerId: 17, name: 'Digital Air Strike', displayName: 'Digital Air Strike', code: 'DIGITALAIRSTRIKE' },
  },
  mediaTypes: {
    search: { mediaTypeId: 1, name: 'Paid' },
    display: { mediaTypeId: 2, name: 'Display' },
    video: { mediaTypeId: 3, name: 'Video' },
    social: { mediaTypeId: 4, name: 'Social' },
    displayRetargeting: { mediaTypeId: 5, name: 'Display Retargeting' },
    socialRetargeting: { mediaTypeId: 6, name: 'Social Retargeting' },
    videoRetargeting: { mediaTypeId: 7, name: 'Video Retargeting' },
  },
  orgDateCodes: {
    calendar: { orgDateCodeId: 1, name: 'Calendar' },
    orgDateCode2: { orgDateCodeId: 2, name: 'OrgDateCode2'},
    orgDateCode3: { orgDateCodeId: 3, name: 'OrgDateCode3' }
  },
  pageTypes: {
    leads: { name: 'Leads', pageTypeId: 1 },
    sales: { name: 'Sales', pageTypeId: 2 },
    digitalRetailing: { name: 'Digital Retailing', pageTypeId: 3 }
  },
  printingOptions: {
    pdf: { id: 1, name: 'PDF' },
    excel: { id: 2, name: 'Excel' }
  },
  referrerQualityGraphViews: {
    channel: { viewId: 1, displayName: 'Channel' },
    mediaType: { viewId: 2, displayName: 'Media Type' },
    total: { viewId: 3, displayName: 'Total' }
  },
  reportDateModes: {
    months: { reportDateModeId: 1, name: 'Months' },
    days: { reportDateModeId: 2, name: 'Days' }
  },
  routeNames: {
    dealerDashboard: '/dealerDashboard',
    dealerPerformanceReport: '/dealerPerformance',
    digitalAdvertisingReport: '/digitalAdvertising',
    districtDashboard: '/districtDashboard',
    leadSummary: '/leads/summary',
    regionDashboard: '/regionDashboard',
    salesDashboard: '/salesDashboard',
    salesSummary: '/sales/summary',
    salesVehicle: '/sales/vehicle',

    sourcePerformanceReport: '/sourcePerformance',
    summaryReport: '/summaryReport',
    transactionsReport: '/transactions',
  },
  routeCategories: {
    digAd: 'digAd',
    sales: 'sales',
    leads: 'leads',
    management: 'management',
    website: 'website',
  },
  salesUnits: {
    orv: { salesUnitId: 1, name: 'ORV' },
    motorcycle: { salesUnitId: 2, name: 'Motorcycle' },
    commercial: { salesUnitId: 3, name: 'Commercial' }
  },
  saleTypes: {
    new: { saleTypeId: 0, name: 'New' },
    used: { saleTypeId: 1, name: 'Used' },
    cpo: { saleTypeId: 3, name: 'CPO' },
    other: { saleTypeId: 4, name: 'Other' }
  },
  sourceTypes: {
    all: { sourceTypeId: 0, name: 'All' },
    brand: { sourceTypeId: 1, name: 'Other Brand' },
    thirdParty: { sourceTypeId: 2, name: 'Third Party' },
    dealerWebsite: { sourceTypeId: 3, name: 'Dealer Website' },
    inventory: { sourceTypeId: 4, name: 'Inventory' },
    event: { sourceTypeId: 5, name: 'Event' },
    dealerGenerated: { sourceTypeId: 6, name: 'Dealer Generated' },
    mbusadotcom: { sourceTypeId: 7, name: 'MBUSA.com' }
  },
  trafficLevelTwoReportModes: {
    uniqueVisitorsBillboard: {
      reportModeId: 1, name: '', metricOneDefault: { metricId: 17, name: 'Unique Visitors' }, metricTwoDefault: { metricId: 1, name: 'Visits' } },
    visitsBillboard: {
      reportModeId: 2, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 17, name: 'Unique Visitors' } },
    actionsBillboard: { reportModeId: 3, name: '', metricOneDefault: { metricId: 3, name: 'Actions' }, metricTwoDefault: { metricId: 4, name: 'Engagement' } },
    engagementBillboard: { reportModeId: 4, name: '', metricOneDefault: { metricId: 4, name: 'Engagement' }, metricTwoDefault: { metricId: 3, name: 'Actions' } },
    channelBarchart: { reportModeId: 5, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 2, name: 'Page Views' } },
    trafficTrend: { reportModeId: 6, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 17, name: 'Unique Visitors' } },
    // vdpsTrend: { reportModeId: 7, name: '', metricOneDefault: { metricId: 5, name: 'Impressions' }, metricTwoDefault: { metricId: 1, name: 'Visits' } },
    // serviceTrend: { reportModeId: 8, name: '', metricOneDefault: { metricId: 5, name: 'Impressions' }, metricTwoDefault: { metricId: 1, name: 'Visits' } },
    referrerQuality: { reportModeId: 9, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 0, name: 'None' } },
    trafficByDeviceType: { reportModeId: 10, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 0, name: 'None' } },
    dealerPerformance: { reportModeId: 11, name: '', metricOneDefault: { metricId: 1, name: 'Visits' }, metricTwoDefault: { metricId: 0, name: 'None' } },
    campaignPerformance: { reportModeId: 12, name: '', metricOneDefault: { metricId: 21, name: 'Media Spend' }, metricTwoDefault: { metricId: 5, name: 'Impressions' } }
  },
  trendColors: {
    green: { id: 1, name: 'green', hexString: '#4daa6d' },
    yellow: { id: 2, name: 'yellow', hexString: '#e5c358' },
    red: { id: 3, name: 'red', hexString: '#d34b4b' },
    blue: { id: 4, name: 'blue', hexString: '#248eb6' },
    gray: { id: 5, name: 'gray', hexString: '#6b6b6b' }
  },
  vehicleEntityTypes: {
    model: { type: 'model', displayName: 'Model' },
    class: { type: 'class', displayName: 'Class' },
    allClasses: { type: 'allclasses', displayName: 'All Classes' }
  },
  vehicleLookupTypes: {
    vehicleClass: { vehicleLookupTypeId: 1, name: 'Class'},
    vehicleModel: { vehicleLookupTypeId: 2, name: 'Model'},
  },
  vehicleMakes: {
    mercedesBenz: 'Mercedes-Benz',
    smart: 'Smart',
    sprinter: 'Sprinter',
    freightLiner: 'Freightliner'
  },
  vehicleStatus: {
    new: { vehicleStatusId: 1, name: 'New' },
    used: { vehicleStatusId: 2, name: 'Used' }
  },
  vehicleModels: {
    allModels: 'All Models',
    cadenza: 'Cadenza',
    forte: 'Forte',
    k900: 'K900',
    niro: 'Niro',
    niroPHEV: 'Niro PHEV',
    niroPluginHybrid: 'Niro Plug-In Hybrid',
    optima: 'Optima',
    optimaHybrid: 'Optima Hybrid',
    optimaPHEV: 'Optima PHEV',
    optimaPluginHybrid: 'Optima Plug-In Hybrid',
    rio: 'Rio',
    rio5door: 'Rio 5-door',
    sedona: 'Sedona',
    sorento: 'Sorento',
    soul: 'Soul',
    soulEv: 'Soul EV',
    sportage: 'Sportage',
    stinger: 'Stinger'
  },
  vehicleClasses: {
    allClasses: 'All Classes'
  },
  websiteProviders: {
    allProviders: { providerId: 0, name: 'All Providers', displayName: 'All Providers', code: 'ALLPROVIDERS' },
    dealerFire: { providerId: 1, name: 'Dealer Fire', displayName: 'Dealer Fire', code: 'DEALERFIRE' },
    dealerInspire: { providerId: 2, name: 'Dealer Inspire', displayName: 'Dealer Inspire', code: 'DEALERINSPIRE' },
    dealerOn: { providerId: 3, name: 'DealerON', displayName: 'DealerON', code: 'DEALERON' },
    dealerDotCom: { providerId: 4, name: 'Dealer.com', displayName: 'Dealer.com', code: 'DEALERCOM' },
    dealerEProcess: { providerId: 5, name: 'Dealer eProcess', displayName: 'Dealer eProcess', code: 'DEALEREPROCESS' },
    dom360: { providerId: 6, name: 'DOM360', displayName: 'DOM360', code: 'DOM360' },
    searchOptics: { providerId: 7, name: 'Search Optics', displayName: 'Search Optics', code: 'SEARCHOPTICS' },
    autoLoop: { providerId: 8, name: 'Auto Loop', displayName: 'Auto Loop', code: 'AUTOLOOP' },
    stream: { providerId: 9, name: 'Stream', displayName: 'Stream', code: 'STREAM' },
    fusionZone: { providerId: 10, name: 'fusionZone', displayName: 'fusionZone', code: 'FUSIONZONE' },
    dealerVenom: { providerId: 11, name: 'Dealer Venom', displayName: 'Dealer Venom', code: 'DEALERVENOM' },
    carNow: { providerId: 12, name: 'CarNow', displayName: 'CarNow', code: 'CARNOW' },
    digitalAirStrike: { providerId: 13, name: 'Digital Air Strike', displayName: 'Digital Air Strike', code: 'DIGITALAIRSTRIKE' },
    tradePending: { providerId: 14, name: 'TradePending', displayName: 'TradePending', code: 'TRADEPENDING' },
    activEngage: { providerId: 15, name: 'ActivEngage', displayName: 'ActivEngage', code: 'ACTIVENGAGE' },
    carChat24: { providerId: 16, name: 'CarChat24', displayName: 'CarChat24', code: 'CARCHAT24' },
    gubagoo: { providerId: 17, name: 'Gubagoo', displayName: 'Gubagoo', code: 'GUBAGOO' },
    livePerson: { providerId: 18, name: 'LivePerson', displayName: 'LivePerson', code: 'LIVEPERSON' },
    podium: { providerId: 19, name: 'Podium', displayName: 'Podium', code: 'PODIUM' },
    edmunds: { providerId: 20, name: 'Edmunds', displayName: 'Edmunds', code: 'EDMUNDS' },
    eBizAutos: { providerId: 21, name: 'eBizAutos', displayName: 'eBizAutos', code: 'EBIZAUTOS' },
    strathcom: { providerId: 22, name: 'Strathcom', displayName: 'Strathcom', code: 'STRATHCOM' },
    sm360: { providerId: 23, name: 'SM360', displayName: 'SM360', code: 'SM360' },
    conversations: { providerId: 24, name: 'Conversations', displayName: 'Conversations', code: 'CONVERSATIONS' },
    cdk: { providerId: 25, name: 'CDK', displayName: 'CDK', code: 'CDK' },
    eDealer: { providerId: 26, name: 'EDealer', displayName: 'EDealer', code: 'EDEALER' },
    pixelMotion: { providerId: 28, name: 'Pixel Motion', displayName: 'Pixel Motion', code: 'PIXELMOTION' },
    allAutoNetwork: { providerId: 29, name: 'All Auto Network', displayName: 'All Auto Network', code: 'ALLAUTONETWORK' },
    agency360: { providerId: 30, name: '360 Agency ', displayName: '360 Agency', code: '360AGENCY' },
    autoTrader: { providerId: 31, name: 'Pixel Motion', displayName: 'Auto Trader', code: 'AUTOTRADER' },
    d2cMedia: { providerId: 32, name: 'All Auto Network', displayName: 'D2C Media', code: 'D2CMEDIA' },
    ezResults: {providerId: 33, name: 'Fox Dealer', displayName: 'EZ Results', code: 'EZRESULTS' },
    foxDealer: {providerId: 46, name: 'Fox Dealer', displayName: 'Fox Dealer', code: 'FOXDEALER' },
    sincro: { providerId: 37, name: 'Sincro', displayName: 'Sincro', code: 'SINCRO' },
    nerdAuto: { providerId: 34, name: 'Nerd Auto', displayName: 'Nerd Auto', code: 'NERDAUTO' },
    magnetis: {providerId: 35, name: 'Magnetis', displayName: 'Magnetis', code: 'MAGNETIS' },
    dx1: { providerId: 33, name: 'DX1', displayName: 'DX1', code: 'DX1' },
    dealerSpike: { providerId: 37, name: 'Dealer Spike', displayName: 'Dealer Spike', code: 'DEALERSPIKE' },
    teamVelocity: {providerId: 36, name: 'Team Velocity', displayName: 'Team Velocity', code: 'TEAMVELOCITY' },
    nabThat: {providerId: 37, name: 'NabThat', displayName: 'NabThat', code: 'NABTHAT' },
  },
  websiteMetrics: {
    none: { metricId: 0, name: 'None', nameKey: null },
    totalVisits: { metricId: 1, name: 'Website Visits', nameKey: 'totalVisits' },
    uniqueVisits: { metricId: 2, name: 'Unique Visitors', nameKey: 'uniqueVisits' },
    pageViews: { metricId: 3, name: 'Page Views', nameKey: 'pageViews' },
    avgTimeOnSite: { metricId: 4, name: 'Avg. Time on Site', nameKey: 'averageTimeOnSite' },
    newInventoryDetailViews: { metricId: 5, name: 'New Inventory Detail Views', nameKey: 'newInventoryDetailViews' },
    usedInventoryDetailViews: { metricId: 6, name: 'Used Inventory Detail Views', nameKey: 'usedInventoryDetailViews' },
    cpoInventoryDetailViews: { metricId: 7, name: 'CPO Inventory Detail Views', nameKey: 'cpoInventoryDetailViews' },
    formSubmissions: { metricId: 8, name: 'Form Submissions', nameKey: 'formSubmissions' },
    clickToCalls: { metricId: 9, name: 'Click to Calls', nameKey: 'clickToCalls' },
    phoneCalls: { metricId: 10, name: 'Phone Calls', nameKey: 'phoneCalls' },
    actions: { metricId: 11, name: 'Actions', nameKey: 'actions' },
    actionRate: { metricId: 12, name: 'Action Rate', nameKey: 'actionRate' },
    engagements: { metricId: 13, name: 'Engagements', nameKey: 'engagements' },
    engagementRate: { metricId: 14, name: 'Engagement Rate', nameKey: 'engagementRate' },
    formConversionRate: { metricId: 15, name: 'Form Conversion Rate', nameKey: 'formConversionRate' },
    phoneConversionRate: { metricId: 16, name: 'Phone Conversion Rate', nameKey: 'phoneConversionRate' },
    totalContacts: { metricId: 17, name: 'Total Contacts', nameKey: 'totalContacts' },
    totalContactRate: { metricId: 18, name: 'Total Contact Rate', nameKey: 'totalContactRate' },
    bounceRate: { metricId: 19, name: 'Bounce Rate', nameKey: 'bounceRate' },
    bounces: { metricId: 20, name: 'Bounces', nameKey: 'bounces' },
    newVdpViews: { metricId: 21, name: 'New VDP Views', nameKey: 'newVdpViews' },
    usedVdpViews: { metricId: 22, name: 'Used VDP Views', nameKey: 'usedVdpViews' },
    cpoVdpViews: { metricId: 23, name: 'CPO VDP Views', nameKey: 'cpoVdpViews' },
    otherVdpViews: { metricId: 24, name: 'Other VDP Views', nameKey: 'otherVdpViews' },
    totalVdpViews: { metricId: 25, name: 'Total VDP Views', nameKey: 'totalVdpViews' },
    newVlpViews: { metricId: 26, name: 'New VLP Views', nameKey: 'newVlpViews' },
    usedVlpViews: { metricId: 27, name: 'Used VLP Views', nameKey: 'usedVlpViews' },
    cpoVlpViews: { metricId: 28, name: 'CPO VLP Views', nameKey: 'cpoVlpViews' },
    otherVlpViews: { metricId: 29, name: 'Other VLP Views', nameKey: 'otherVlpViews' },
    mixedVlpViews: { metricId: 30, name: 'Mixed VLP Views', nameKey: 'mixedVlpViews' },
    totalVlpViews: { metricId: 31, name: 'Total VLP Views', nameKey: 'totalVlpViews' },
    pageViewsPerVisit: { metricId: 32, name: 'Page Views Per Visit', nameKey: 'pageViewsPerVisit' },
    tiguanPageViews: { metricId: 33, name: 'Tiguan Page Views', nameKey: 'tiguanPageViews' },
    atlasPageViews: { metricId: 34, name: 'Atlas Page Views', nameKey: 'atlasPageViews' },
    jettaPageViews: { metricId: 35, name: 'Jetta Page Views', nameKey: 'jettaPageViews' },
    warrantyPageViews: { metricId: 36, name: 'Warranty Page Views', nameKey: 'warrantyPageViews' },
    offersIncentivesPageViews: { metricId: 37, name: 'Offers & Incentives Page Views', nameKey: 'offersIncentivesPageViews' },
    cpoPageViews: { metricId: 38, name: 'CPO Page Views', nameKey: 'cpoPageViews' },
    totalLandingPageViews: { metricId: 39, name: 'Total Landing Page Views', nameKey: 'totalLandingPageViews' },
    accessoriesHomePageViews: { metricId: 40, name: 'Accessories Home Page Views', nameKey: 'accessoriesHomePageViews' },
    competitiveAdvantagePageViews: { metricId: 41, name: 'Competitive Advantage Page Views', nameKey: 'competitiveAdvantagePageViews' },
    dealerCouponPageViews: { metricId: 42, name: 'Dealer Coupon Page Views', nameKey: 'dealerCouponPageViews' },
    driverGearPageViews: { metricId: 43, name: 'Driver Gear Page Views', nameKey: 'driverGearPageViews' },
    maintenanceMenuPageViews: { metricId: 44, name: 'Maintenance Menu Page Views', nameKey: 'maintenanceMenuPageViews' },
    partsHomePageViews: { metricId: 45, name: 'Parts Home Page Views', nameKey: 'partsHomePageViews' },
    scheduleServiceClicks: { metricId: 46, name: 'Schedule Service Clicks', nameKey: 'scheduleServiceClicks' },
    scheduleServicePageViews: { metricId: 47, name: 'Schedule Service Page Views', nameKey: 'scheduleServicePageViews' },
    serviceCcPageViews: { metricId: 48, name: 'Service Cc Page Views', nameKey: 'serviceCcPageViews' },
    serviceHomePageViews: { metricId: 49, name: 'Service Home Page Views', nameKey: 'serviceHomePageViews' },
    serviceSpecialPageViews: { metricId: 50, name: 'Service Special Page Views', nameKey: 'serviceSpecialPageViews' },
    serviceXpressPageViews: { metricId: 51, name: 'Service Xpress Page Views', nameKey: 'serviceXpressPageViews' },
    tireStorePageViews: { metricId: 52, name: 'Tire Store Page Views', nameKey: 'tireStorePageViews' },
    totalAfterSalesPageViews: { metricId: 53, name: 'Total After Sales Page Views', nameKey: 'totalAfterSalesPageViews' },
    vwCarePageViews: { metricId: 54, name: 'VW Care Page Views', nameKey: 'vwCarePageViews' },
    vdpViews: { metricId: 55, name: 'VDP Views', nameKey: 'vdpViews' },
    servicePageViews: { metricId: 56, name: 'Service Page Views', nameKey: 'servicePageViews' },
    dealerEnrollments: { metricId: 57, name: 'Dealer Enrollments', nameKey: 'dealerEnrollments' },
    avgActions: { metricId: 58, name: 'Avg. Actions', nameKey: 'averageActions' },
    avgEngagementRate: { metricId: 59, name: 'Avg. Engagement Rate', nameKey: 'averageEngagementRate' },
    avgVisitors: { metricId: 60, name: 'Avg. Visitors', nameKey: 'averageVisitors' },
    websiteVisits: { metricId: 61, name: 'Website Visits', nameKey: 'websiteVisits' },
    clickToCallRate: { metricId: 62, name: 'Click to Call Rate', nameKey: 'clickToCallRate' },
    qualifiedVisits: { metricId: 63, name: 'Qualified Visits', nameKey: 'qualifiedVisits' },
    salesPageViews: { metricId: 64, name: 'Sales Page Views', nameKey: 'salesPageViews' },
    hoursAndDirections: { metricId: 65, name: 'Hours & Directions', nameKey: 'hoursAndDirections' },
    windowStickers: { metricId: 66, name: 'Window Stickers', nameKey: 'windowStickers' },
    onlineServiceScheduler: { metricId: 67, name: 'Online Service Scheduler', nameKey: 'onlineServiceScheduler' },
    tireFinderViews: { metricId: 68, name: 'Tire Finder Views', nameKey: 'tireFinderViews' },
    bannerEngagements: { metricId: 69, name: 'Banner Engagements', nameKey: 'bannerEngagements' },
    salesSessions: { metricId: 70, name: 'Sales Sessions', nameKey: 'salesSessions' },
    serviceSessions: { metricId: 71, name: 'Service Sessions', nameKey: 'serviceSessions' },
    salesSessionsConversionRate: { metricId: 72, name: 'Sales Sessions Conversion Rate', nameKey: 'salesSessionsConversionRate' },
    serviceSessionsConversionRate: { metricId: 73, name: 'Service Sessions Conversion Rate', nameKey: 'serviceSessionsConversionRate' },
  },
  hospitalityWebsiteMetrics: {
    none: { metricId: 0, name: 'None', nameKey: null },
    visits: { metricId: 1, name: 'Visits', nameKey: 'visits' },
    looks: { metricId: 2, name: 'Looks', nameKey: 'roomSearches' },
    visitorsToLookRate: { metricId: 3, name: 'Visitor to Look %', nameKey: 'roomSearchRate' },
    bookings: { metricId: 4, name: 'Bookings', nameKey: 'bookingsCompleted' },
    bookingInitiation: { metricId: 5, name: 'Booking Initiation', nameKey: 'bookNowClicks' },
    bookingInitiationRate: { metricId: 6, name: 'Booking Initiation %', nameKey: 'bookNowClickRate' },
    roomNights: { metricId: 7, name: 'Room Nights', nameKey: 'bookingRoomNights' },
    revenue: { metricId: 8, name: 'Revenue', nameKey: 'bookedTotalRevenue' },
    pageViewPerVisit:{ metricId: 9, name: 'Page Views Per Visit', nameKey: 'pageViewsPerUniqueVisitor' },
    websiteConversions: { metricId: 10, name: 'Website Conversions', nameKey: 'websiteConversions' },
    avgDailyRate: { metricId: 11, name: 'Avg. Daily Rate', nameKey: 'revenuePerNight' },
    lengthOfStay:{ metricId: 12, name: 'Length of Stay', nameKey: 'lengthOfStay' },
    uniqueVisitors:{ metricId: 13, name: 'Unique Visitors', nameKey: 'uniqueVisitors' },
    clickToCalls: { metricId: 14, name: 'Click To Calls', nameKey: 'clickToCalls' },
    visitorsToBookRate: { metricId: 15, name: 'Visitor to Book %', nameKey: 'bookingsCompletedRate' },
    lookToBookRate: { metricId: 16, name: 'Look to Book %', nameKey: 'lookToBookRate' },
  }
};

export type dateModesValues = typeof Enums.dateModes[IDateModeEnumKeys];
