import {
    IDefaultTableColumnConfig,
    IWebsiteVehicleConfig,
    IMultiLevelDualAxisLineGraphConfig,
    ICardHeaderConfig,
    INameValuePair
    } from '../../../../_shared/models/models-index';

export class WebsiteVehicleConfig implements IWebsiteVehicleConfig {
    public reportTitle: string;
    public benchmarkCardHeader: ICardHeaderConfig = null;
    public graphConfig: IMultiLevelDualAxisLineGraphConfig = null;
    public summaryTableColumns: IDefaultTableColumnConfig[] = [];
    public metricDisplayModes: INameValuePair[] = [];
}
